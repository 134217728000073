import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { VEHICLE_UNKNOWN, VEHICLE_MISSING_LOCATION } from './errorFingerprints'

export class YscApiVehicleError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    if (args.apiResponseErrorData && typeof args.apiResponseErrorData === 'object' && 'location' in args.apiResponseErrorData && args.apiResponseErrorData.location) {
      this._fields = Object.entries(args.apiResponseErrorData.location).map(([key, value]) => ({ label: { text: key, needTranslation: false }, description: { text: Array.isArray(value) ? value.join(' ') : value, needTranslation: false } }))
      this.fingerprint = VEHICLE_MISSING_LOCATION
      return
    }

    this.fingerprint = VEHICLE_UNKNOWN
  }
}
