import { parseDateStringFromQuery } from './parseDateStringFromQuery'
import type { CamperQueryValidator } from '~/types/camperQuery'

export const validateQueryDateAreOpenDays: CamperQueryValidator = ({ query, camper }) => {
  const dateFrom = queryValueToString(query.date_from)
  const dateTo = queryValueToString(query.date_to)
  if (!dateFrom || !dateTo) {
    return { mustRedirect: true, nextQuery: removeDatesAndHoursFromCamperQuery(query) }
  }
  const interval = {
    start: parseDateStringFromQuery(dateFrom),
    end: parseDateStringFromQuery(dateTo),
  }

  if (!camper.open_days) {
    return {
      mustRedirect: false,
      nextQuery: query,
      validationErrorLogs: [],
    }
  }

  const openDays = [
    camper.open_days.sun_is_open,
    camper.open_days.mon_is_open,
    camper.open_days.tue_is_open,
    camper.open_days.wed_is_open,
    camper.open_days.thu_is_open,
    camper.open_days.fri_is_open,
    camper.open_days.sat_is_open,
  ]

  const isValid = openDays[interval.start.getDay()] && openDays[interval.end.getDay()]

  if (!isValid) {
    return {
      mustRedirect: true,
      nextQuery: removeDatesAndHoursFromCamperQuery(query),
      validationErrorLogs: ['Selected dates are not open days'],
    }
  }

  return {
    mustRedirect: false,
    nextQuery: query,
    validationErrorLogs: [],
  }
}
