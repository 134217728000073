<script setup lang="ts">
import type { AppHeaderMenuProps } from '../Header/AppHeaderMenu.vue'

withDefaults(defineProps<AppHeaderMenuProps>(), {
  showAppLink: false,
})

const { user } = useUserStore()
const { open, onToggleOpen, onClose } = useDropdownPuppet()
</script>

<template>
  <YscDropdown
    :open="open"
    direction="bottom"
    anchor="right"
    content-offset="mt-10"
    fixed-width="w-72"
    @toggle-open="onToggleOpen"
    @close="onClose"
  >
    <template #label>
      <div class="flex items-center">
        <YscAvatar
          :first-name="user?.first_name"
          :picture="user?.profile_picture?.url"
          radius="15"
        />
      </div>
    </template>
    <template #icon>
      <YscIconsBurger class="w-6 h-6 text-black" />
    </template>

    <AppMenuLinkList
      :show-app-link="!showAppLink"
      class="p-2"
      @click="onClose"
    />
  </YscDropdown>
</template>
