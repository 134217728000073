import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { I18N_UNTRANSLATED_FIELD, I18N_UNTRANSLATED_LINK } from './errorFingerprints'

export interface YscI18nErrorParams extends YscErrorParams {
  type?: 'field' | 'link'
  key?: string
}

export class YscI18nError extends YscError {
  constructor({ type, key, ...args }: YscI18nErrorParams) {
    super({ ...args, message: `Untranslated ${type} ${key}` })

    // For the breadcrumb
    if (type === 'link') {
      this.fingerprint = I18N_UNTRANSLATED_LINK
      return
    }

    // For the error toast
    if (type === 'field') {
      this.fingerprint = I18N_UNTRANSLATED_FIELD
    }
  }
}
