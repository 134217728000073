export const useSeoImage = ({ imagePath = undefined }: { imagePath?: MaybeRefOrGetter<string | null> } = {}) => {
  let imageHref = toValue(imagePath)
  if (!imageHref?.startsWith('https')) {
    const { seoImageHref } = useSeoImageHref()
    imageHref = seoImageHref(toValue(imagePath))
  }
  useHead({
    meta: [
      getMetaProperty({ property: 'og:image', content: imageHref }),
      getHeadMetaName({ name: 'twitter:image', content: imageHref }),
    ],
  })
}
