import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { INVALID_PLACE } from './errorFingerprints'

export class YscApiPlacesError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = INVALID_PLACE
  }
}
