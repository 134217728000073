<script setup lang="ts">
import type { AppHeaderMenuProps } from '../Header/AppHeaderMenu.vue'

const props = withDefaults(defineProps<AppHeaderMenuProps>(), {
  showAppLink: false,
})

const { t } = useI18n()
const route = useRoute()
const { logOut } = useUserStore()
const { isLoggedIn, isOwner, isStaff } = storeToRefs(useUserStore())
const { routing } = storeToRefs(useRoutingStore())

const { hasOnlyOneCamper } = storeToRefs(useCamperStore())

const menuLinkComponent = resolveComponent('AppMenuLink')
const menuDividerComponent = resolveComponent('AppMenuDivider')
const icons = {
  apps: resolveComponent('YscIconsApps'),
  calendarCheck: resolveComponent('YscIconsCalendarCheck'),
  edit: resolveComponent('YscIconsEdit'),
  rent: resolveComponent('YscIconsRent'),
  world: resolveComponent('YscIconsWorld'),
  heart: resolveComponent('YscIconsHeart'),
  user: resolveComponent('YscIconsUser'),
  search: resolveComponent('YscIconsSearch'),
  login: resolveComponent('YscIconsLogin'),
  logout: resolveComponent('YscIconsLogout'),
  info: resolveComponent('YscIconsInfo'),
  lifeRing: resolveComponent('YscIconsLifeRing'),
  signup: resolveComponent('YscIconsSignup'),
  yescapa: resolveComponent('YscIconsYescapa'),
}

const { getTranslatedLink } = useGetTranslatedLink()
const mainLinks = computed(() => {
  const links = []

  if (isStaff.value) {
    links.push({
      component: resolveComponent('AppMenuLinksStaff'),
    }, {
      component: menuDividerComponent,
    })
  }

  if (props.showAppLink) {
    links.push({
      component: menuLinkComponent,
      props: {
        link: {
          to: { name: 'get-apps' },
          label: t('commons.links.get-apps'),
          icon: icons.apps,
        },
      },
      class: 'font-semibold',
    }, {
      component: menuDividerComponent,
    })
  }

  // main section
  if (isLoggedIn.value) {
    if (isOwner.value) {
      links.push(
        {
          component: menuLinkComponent,
          props: {
            link: {
              to: { name: 'd-bookings' },
              label: getTranslatedLink('d-bookings'),
              icon: icons.calendarCheck,
            },
          },
        },
        {
          component: menuLinkComponent,
          props: {
            link: {
              to: { name: 'd-campers' },
              label: t('commons.links.products_plural', hasOnlyOneCamper.value ? 1 : 2),
              icon: icons.edit,
            },
          },
        },
      )
    }
    else {
      links.push(
        {
          component: menuLinkComponent,
          props: {
            link: {
              to: { name: 'd-trips' },
              label: getTranslatedLink('d-trips'),
              icon: icons.world,
            },
          },
        },
        {
          component: menuLinkComponent,
          props: {
            link: {
              to: { name: 'd-wishlists' },
              label: getTranslatedLink('d-wishlists'),
              icon: icons.heart,
            },
          },
        },
      )
    }
    links.push(
      {
        component: menuLinkComponent,
        props: {
          link: {
            to: { name: 'd-account' },
            label: getTranslatedLink('d-account'),
            icon: icons.user,
          },
        },
      },
      { component: menuDividerComponent },
    )
  }
  else {
    links.push(
      {
        component: menuLinkComponent,
        props: {
          link: {
            to: { name: 'login', query: { next: route.fullPath } },
            label: getTranslatedLink('login'),
            icon: icons.login,
            isPrimary: true,
          },
        },
      },
      {
        component: menuLinkComponent,
        props: {
          link: {
            to: routing.value?.sign_up,
            label: getTranslatedLink('signup'),
            icon: icons.signup,
            isPrimary: true,
          },
        },
      },
      { component: menuDividerComponent },
    )
  }

  links.push({
    component: menuLinkComponent,
    props: {
      link: {
        to: { name: 's' },
        label: getTranslatedLink('s'),
        icon: icons.search,
      },
    },
  })

  if (isLoggedIn.value) {
    links.push({
      component: menuLinkComponent,
      props: {
        link: {
          to: { name: 'onboarding-step' },
          label: getTranslatedLink('onboarding-step'),
          icon: icons.rent,
        },
      },
    })
  }

  links.push({
    component: menuDividerComponent,
  }, {
    component: menuLinkComponent,
    props: {
      link: {
        to: { name: 'how-yescapa-works' },
        label: getTranslatedLink('how-yescapa-works'),
        icon: icons.yescapa,
      },
    },
  }, {
    component: menuLinkComponent,
    props: {
      link: {
        to: routing.value?.faq_home,
        label: getTranslatedLink('help-center'),
        icon: icons.lifeRing,
      },
    },
  })

  return links
})
</script>

<template>
  <nav>
    <component
      :is="link.component"
      v-for="(link, i) of mainLinks"
      :key="`app-menu-user-link-${i}`"
      :class="link.class"
      v-bind="link.props"
    />

    <template v-if="isLoggedIn">
      <AppMenuDivider />

      <AppMenuLink
        :link="{
          icon: icons.logout,
          label: $t('commons.links.logout'),
        }"
        @click="logOut()"
      />
    </template>
  </nav>
</template>
