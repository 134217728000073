<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router'

const { camper } = storeToRefs(useCamperStore())
const route = useRoute()
const { public: { djangoUrl } } = useRuntimeConfig()

const links = computed(() => {
  const items: {
    id: string
    label: string
    to: RouteLocationRaw | string
    external?: boolean
    target?: string
    icon: ReturnType<typeof resolveComponent>
    isPrimary: boolean
  }[] = [{ id: 'log-as', label: 'Log as page', to: { name: 'login-log-as' }, isPrimary: true, icon: resolveComponent('YscIconsLogin') }]

  if (route.name === 's') {
    const { query } = route
    const searchToolUrl = new URL('/ryansimmons/vehicles/proxyonboarding/search-tool/', djangoUrl)
    Object.entries(query).forEach(([key, value]) => {
      const queryValue = queryValueToString(value)
      if (queryValue) {
        searchToolUrl.searchParams.append(key, queryValue)
      }
    })
    items.push({ id: 'backoffice-search-tool', label: 'Search tool', to: searchToolUrl.href, external: true, target: '_blank', isPrimary: true, icon: resolveComponent('YscIconsSearch') })
  }

  if (camper.value?.admin_urls) {
    if (camper.value.admin_urls.camper_url) {
      items.push({ id: 'backoffice-camper-link', label: `Last camper ${camper.value.id}`, to: camper.value.admin_urls.camper_url, isPrimary: true, icon: resolveComponent('YscIconsVehicle') })
    }

    if (camper.value.admin_urls.owner_url) {
      items.push({ id: 'backoffice-owner-link', label: `Last owner ${camper.value.vehicle_owner_first_name}`, to: camper.value.admin_urls.owner_url, isPrimary: true, icon: resolveComponent('YscIconsUserPro') })
    }
  }
  return items
})
</script>

<template>
  <AppMenuLink
    v-for="link in links"
    :key="link.id"
    :link="link"
  />
</template>
