import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { INSURANCE_UNKNOWN, RENTAL_COVER_SERVICE_UNAVAILABLE } from './errorFingerprints'
import { isErrorCausedByRentalCoverUnavailability } from './isErrorCausedByRentalCoverUnavailability'

export class YscApiInsuranceError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    let fingerprint = INSURANCE_UNKNOWN
    if (isErrorCausedByRentalCoverUnavailability(this)) {
      fingerprint = RENTAL_COVER_SERVICE_UNAVAILABLE
    }

    this.fingerprint = fingerprint
  }
}
