const overrideDefault = {
  style: {
    backgroundColor: null, // needed because of v-calendar default
  },
}

const draggedDay = {
  ...overrideDefault,
  class: 'bg-pink-400',
  contentClass: 'text-white', // won't apply if there is a #day-content template
}

const selectedDay = {
  ...draggedDay,
  class: 'bg-pink-500',
}

const baseDay = {
  ...overrideDefault,
  class: 'bg-pink-100',
}

export const dragAttribute = {
  highlight: {
    start: {
      ...selectedDay,
      class: selectedDay.class + ' !rounded-r-none !rounded-l',
    },
    end: {
      ...overrideDefault,
      class: 'bg-white border border-pink-500 !rounded-l-none !rounded-r',
    },
    base: baseDay,
  },
}

export const selectAttribute = {
  highlight: {
    start: {
      ...selectedDay,
      class: selectedDay.class + ' !rounded-r-none !rounded-l',
    },
    end: {
      ...selectedDay,
      class: selectedDay.class + ' !rounded-l-none !rounded-r',
    },
    base: baseDay,
  },
}

export const setDateAsStartDate = (date: Date) => {
  date.setHours(0, 0, 0, 0)
  return date
}

export const setDateAsEndDate = (date: Date) => {
  date.setHours(23, 59, 59, 0)
  return date
}
