import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { MANGOPAY_UNKNOWN } from './errorFingerprints'

export class YscApiMangopayError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = MANGOPAY_UNKNOWN
  }
}
