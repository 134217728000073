import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { GOOGLE_MAPS_UNKNOWN } from './errorFingerprints'

export class YscGoogleMapsError extends YscError {
  constructor(args: YscErrorParams) {
    super(args)

    this.fingerprint = GOOGLE_MAPS_UNKNOWN
  }
}
