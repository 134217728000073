import type { InsuranceListItemResponse, Currencies } from '@yescapa-dev/ysc-api-js/legacy'
import type { FormattedInsurance, InsurancePriceDetail, InsurancePriceKeys } from '~/types/insurances'

export const useFormatInsurances = () => {
  const { $i18n: { t } } = useNuxtApp()

  const { public: { currency: { default: defaultCurrency } } } = useRuntimeConfig()

  return ({ insurances = [], currencies = {}, insurance_id = null }:
  {
    insurances: InsuranceListItemResponse[]
    currencies: Record<string, {
      currency: Currencies
      currencyDisplay: 'symbol'
      style: 'currency'
    }>
    insurance_id?: number | null
  }) => {
    const getCurrency = (currency: string | null) => ({
      ...currencies[currency || defaultCurrency],
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const getInsurancePriceDetails = (insurance: InsuranceListItemResponse) => {
      const insurancePriceDetails: InsurancePriceDetail<InsurancePriceKeys>[] = [
        {
          key: 'damage_to_vehicle',
          text: t('data.insurance.damage_to_vehicle.label'),
          price: insurance.damage_to_vehicle_price ? parseFloat(insurance.damage_to_vehicle_price) : 0,
          price_key: 'damage_to_vehicle_price',
          currency: getCurrency(insurance.damage_to_vehicle_price_currency),
          currency_key: 'damage_to_vehicle_price_currency',
          display: !!insurance.damage_to_vehicle_price,
          isExclusive: false,
        },
        {
          key: 'damage_high_part',
          text: t('data.insurance.damage_high_part.label'),
          price: insurance.damage_high_part_price ? parseFloat(insurance.damage_high_part_price) : 0,
          price_key: 'damage_high_part_price',
          currency: getCurrency(insurance.damage_high_part_price_currency),
          currency_key: 'damage_high_part_price_currency',
          display: !!insurance.damage_high_part_price,
          isExclusive: false,
        },
        {
          key: 'burglary',
          text: t('data.insurance.burglary.label'),
          price: insurance.burglary_price ? parseFloat(insurance.burglary_price) : 0,
          price_key: 'burglary_price',
          currency: getCurrency(insurance.burglary_price_currency),
          currency_key: 'burglary_price_currency',
          display: !!insurance.burglary_price,
          isExclusive: false,
        },
        {
          key: 'broken_glass',
          text: t('data.insurance.broken_glass.label'),
          price: insurance.broken_glass_price ? parseFloat(insurance.broken_glass_price) : 0,
          price_key: 'broken_glass_price',
          currency: getCurrency(insurance.broken_glass_price_currency),
          currency_key: 'broken_glass_price_currency',
          display: !!insurance.broken_glass_price,
          isExclusive: false,
        },
        {
          key: 'objects_protection',
          text: t('data.insurance.protection_objects.label'),
          price: insurance.objects_protection_price !== null ? parseFloat(insurance.objects_protection_price) : 0,
          price_key: 'objects_protection_price',
          currency: getCurrency(insurance.objects_protection_price_currency),
          currency_key: 'objects_protection_price_currency',
          display: !!insurance.objects_protection_price,
          isExclusive: insurance.is_object_protection_exclusive,
        },
      ]

      return insurancePriceDetails.filter(({ display }) => display)
    }

    return insurances.map((insurance): FormattedInsurance => {
      const insurancePriceDetails = getInsurancePriceDetails(insurance)

      return {
        ...insurance,
        internal_damage_price: null,
        internal_damage_price_currency: null,
        isActive: insurance.id === insurance_id,
        priceDetailsList: insurancePriceDetails.filter(({ isExclusive }) => !isExclusive),
        priceDetailsExclusiveList: insurancePriceDetails.filter(({ isExclusive }) => isExclusive),
      }
    })
      .reverse()
  }
}
