export const useGtag = () => {
  const nuxtApp = useNuxtApp()

  const event = (name: string, args?: Record<string, any>) => {
    if (!name) {
      return
    }
    nuxtApp.$gtm?.('event', name, args)
  }
  return { event }
}
