import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { NEWSLETTER_EMAIL_ALREADY_EXISTS, NEWSLETTER_EMAIL_IS_INVALID, NEWSLETTER_UNKNOWN } from './errorFingerprints'

export class YscApiNewsletterError extends YscError {
  code?: string
  apiURL?: string
  email?: string
  listIds?: any

  constructor({
    code,
    apiURL,
    email,
    listIds,
    ...args
  }: {
    code?: string
    apiURL?: string
    email?: string
    listIds?: any
  } & YscErrorParams) {
    super(args)
    this.code = code
    this.apiURL = apiURL
    this.email = email
    this.listIds = listIds

    if (code === 'email_already_exists') {
      this.fingerprint = NEWSLETTER_EMAIL_ALREADY_EXISTS
      return
    }

    if (code === 'email_is_invalid') {
      this.fingerprint = NEWSLETTER_EMAIL_IS_INVALID
      return
    }

    this.fingerprint = NEWSLETTER_UNKNOWN
  }

  get sentryAdditionalData() {
    return {
      ...super.sentryAdditionalData,
      code: this.code,
      apiURL: this.apiURL,
      email: this.email,
      listIds: this.listIds,
    }
  }
}
