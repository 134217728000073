import type { Camper } from '@yescapa-dev/ysc-api-js/legacy'
import type { LocationQuery } from '#vue-router'
import type { CamperQueryValidator } from '~/types/camperQuery'

export const validateQueryKilometerParameters = ({ query, camper }: { query: LocationQuery, camper: Camper }): ReturnType<CamperQueryValidator> => {
  const validationErrorLogs: string[] = []
  const nextQuery = { ...query }
  let kilometers = query.kilometers || null,
    mustRedirect = false

  if (!camper.unlimited_km_allowed && kilometers === '0') {
    validationErrorLogs.push(`Unlimited kilometers not allowed - query value is "${kilometers}"`)
    kilometers = null
  }

  if (camper.unlimited_km_allowed && camper.price_km_unlimited === 0) {
    validationErrorLogs.push(`Unlimited kilometers is free - query value is "${kilometers}"`)
    nextQuery.kilometers = '0'
  }
  else if (camper.price_km_200 === 0 && kilometers !== '0') {
    validationErrorLogs.push(`200 kilometers is free - query value is "${kilometers}"`)
    nextQuery.kilometers = '200'
  }
  else if (kilometers === null) {
    validationErrorLogs.push(`100 kilometers is default value - query value is "${kilometers}"`)
    nextQuery.kilometers = '100'
  }

  mustRedirect = nextQuery.kilometers !== kilometers

  return { mustRedirect, nextQuery, validationErrorLogs }
}
