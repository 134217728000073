import type { RouteLocationNormalized } from '#vue-router'

export const getBookingPaymentQueryParameters = (route: RouteLocationNormalized) => {
  const { id, insurance_id, cancel_insurance, rental_cover, coupon_code } = route.query

  return {
    id: queryValueToNumber(id),
    insurance_id: queryValueToNumber(insurance_id),
    cancel_insurance: queryValueToBooleanOrNull(cancel_insurance),
    rental_cover: queryValueToBooleanOrNull(rental_cover),
    coupon_code: queryValueToString(coupon_code),
  }
}
