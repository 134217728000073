import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { CHAT_UNKNOWN } from './errorFingerprints'

export class YscApiChatError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = CHAT_UNKNOWN
  }
}
