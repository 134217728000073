export default defineNuxtPlugin({
  name: 'ysc:mandatory-actions-client',
  dependsOn: ['ysc:sentry', 'ysc:gtm'],
  parallel: true,
  setup() {
    const { setSentryUser } = useSetSentryUser()
    const { setAnalyticsUser } = useSetAnalyticsUser()
    setSentryUser()
    setAnalyticsUser()
  },
})
