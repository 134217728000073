import type { AuthTokenResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { ACCESS_TOKEN, REFRESH_ACCESS_TOKEN } from '~/constants/auth'

export function usePersistAPITokens({ tokens }: { tokens: AuthTokenResponse }) {
  // Required for custom hosts like http://yescapa.com.local:3000
  const { public: { devFlag: { disableSecureCookie } } } = useRuntimeConfig()

  const options: {
    path: string
    secure: boolean
    sameSite: true | false | 'lax' | 'none' | 'strict'
  } = {
    path: '/',
    secure: !disableSecureCookie,
    sameSite: 'lax',
  }

  /*
  * API returns expires_in in seconds and cookies need maxAge in seconds.
  * Substract two minutes to exact expiration time because of network latency.
  * It could lead to complexe behavior when a request is invalidated during the fetching time.
  */
  const TWO_MINUTES_IN_SECONDS = 60 * 2
  const accessTokenCookie = useCookie(ACCESS_TOKEN, {
    maxAge: tokens.expires_in > TWO_MINUTES_IN_SECONDS ? tokens.expires_in - TWO_MINUTES_IN_SECONDS : -1,
    ...options,
  })
  accessTokenCookie.value = tokens.access_token

  /*
  * API's refresh_token never expire and cookies need date, set it to one year
   */
  const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365
  const refreshTokenCookie = useCookie(REFRESH_ACCESS_TOKEN, {
    maxAge: ONE_YEAR_IN_SECONDS,
    ...options,

  })
  refreshTokenCookie.value = tokens.refresh_token
}
