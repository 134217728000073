import { YscApiError } from './YscApiError'
import { PAYMENT_INVALID_BOOKING_STATE, PAYMENT_UNKNOWN } from './errorFingerprints'

export class YscApiPaymentFinalizationError extends YscApiError {
  constructor(args) {
    super(args)

    const { apiResponseErrorData, apiStatus } = args

    if (apiStatus === 405 && apiResponseErrorData?.code === 'BOOKING_NOT_IN_VALID_STATE') {
      this.fingerprint = PAYMENT_INVALID_BOOKING_STATE
    }
    else {
      this.fingerprint = PAYMENT_UNKNOWN
    }
  }
}
