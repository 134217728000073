import type { TranslationError } from '~/types/commons'
import { YSC_ERROR } from '~/utils/error/YscErrorClasses'

const routeNameKeyMap: Record<string, string> = {
  'community': 'commons.links.community',
  'community-all-reviews': 'commons.links.community_all_reviews',
  'community-pictures-wall': 'commons.links.community_pictures_wall',
  'confidentiality': 'commons.links.confidentiality',
  'd': 'commons.links.dashboard',
  'd-account': 'commons.links.account',
  'd-bookings': 'commons.links.d_bookings',
  'd-bookings-id': 'commons.links.d_bookings_id',
  'd-campers-id-calendar': 'commons.links.calendar',
  'd-chats': 'commons.links.chats',
  'd-trips': 'commons.links.requests',
  'd-reviews': 'commons.links.reviews',
  'd-wishlists': 'commons.links.wishlists',
  'index': 'commons.links.index',
  'login': 'commons.links.login',
  'how-yescapa-works': 'commons.how_it_works',
  'discover-process': 'commons.links.discover_process',
  'help-center': 'commons.links.help_center',
  'find-answers': 'commons.links.find_answers',
  'faq': 'commons.links.faq',
  'onboarding': 'commons.links.onboarding_step',
  'onboarding-step': 'commons.links.onboarding_step',
  'privacy-confidentiality': 'commons.links.privacy-confidentiality',
  'privacy-cookies': 'commons.links.privacy-cookies',
  's': 'commons.links.search',
  'signup': 'commons.links.signup',
  'terms': 'commons.links.terms',
  'travelers': 'commons.travelers',
  'travelers-hit-the-road': 'commons.links.travelers_hit_the_road',
  'travelers-rv-beginner': 'commons.links.travelers_rv_beginner',
  'we-love-california': 'commons.links.we_love_california',
}

export const useGetTranslatedLink = () => {
  const { $i18n: { t } } = useNuxtApp()
  const { $errorManager } = useErrorManager()
  return {
    getTranslatedLink: (routeName: string | null | undefined) => {
      if (!routeName) {
        return routeName
      }

      if (!routeNameKeyMap[routeName]) {
        const e = new Error('Untranslated link') as TranslationError
        e.type = 'link'
        e.key = routeName
        $errorManager({ e, name: YSC_ERROR })
        return routeName
      }

      return t(routeNameKeyMap[routeName])
    },
  }
}
