import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { SEARCH_INVALID_PAGE, SEARCH_UNKNOWN } from './errorFingerprints'

export class YscApiSearchError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    let fingerprint = SEARCH_UNKNOWN

    if (args.apiStatus === 404 && this.apiEndpoint === '/3/products/') {
      // Search endpoint can give 404 error if page is not valid.
      // Apply specific fingerprint because it's not something on our end
      fingerprint = SEARCH_INVALID_PAGE
    }

    this.fingerprint = fingerprint
  }
}
