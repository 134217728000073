import type { DocumentStatus } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

export const useDocumentsMergedStatus = ({ documents }: { documents: { status: DocumentStatus }[] }) => {
  if (!documents.length) {
    return null
  }

  const { VALIDATED, NEEDDOC, VALIDATION_ASKED } = Constants.DOCUMENTS.STATUS
  if (documents.every(({ status }) => status === NEEDDOC || status === VALIDATED)) {
    return VALIDATED
  }

  if (documents.every(({ status }) => status === NEEDDOC || status === VALIDATED || status === VALIDATION_ASKED)) {
    return VALIDATION_ASKED
  }

  return null
}
