export const ACLASS_1 = '/library/media/pictures/frontend/aclass-1.jpg'
export const ACLASS_AND_FOREST = '/library/media/pictures/frontend/aclass-and-forest.jpg'
export const ACLASS_AND_SEA_1 = '/library/media/pictures/frontend/aclass-and-sea-1.jpg'
export const AERIAL_CAMPER = '/library/media/pictures/frontend/aerial-camper.jpg'
export const AERIAL_LANDSCAPE = '/library/media/pictures/frontend/aerial-landscape.jpg'
export const AERIAL_VERTICAL = '/library/media/pictures/frontend/aerial-vertical.jpg'
export const BUILDING_INTERIOR_WOOD_WIP = '/library/media/pictures/frontend/building-interior-wood-wip.jpg'
export const BUILDING_VAN_INTERIOR = '/library/media/pictures/frontend/building-van-interior.jpg'
export const BUILDING_VAN_ROOF = '/library/media/pictures/frontend/building-van-roof.jpg'
export const CAMPER_AND_FOREST = '/library/media/pictures/frontend/camper-and-forest.jpg'
export const CAMPER_INTERIOR_1 = '/library/media/pictures/frontend/camper-interior-1.jpg'
export const CAMPER_INTERIOR_2 = '/library/media/pictures/frontend/camper-interior-2.jpg'
export const CAMPER_INTERIOR_3 = '/library/media/pictures/frontend/camper-interior-3.jpg'
export const CAMPER_INTERIOR_4 = '/library/media/pictures/frontend/camper-interior-4.jpg'
export const CAMPERVAN = '/library/media/pictures/frontend/campervan.jpg'
export const CAMPERVAN_AND_MONTAIN_1 = '/library/media/pictures/frontend/campervan-and-mountain-1.jpg'
export const CARAVAN = '/library/media/pictures/frontend/caravan.jpg'
export const CARAVAN_AND_SEA = '/library/media/pictures/frontend/caravan-and-sea.jpg'
export const CC_FRONT_EXEMPLE_1 = '/library/media/pictures/frontend/cc-front-exemple-1.jpg'
export const CC_FRONT_EXEMPLE_2 = '/library/media/pictures/frontend/cc-front-exemple-2.jpg'
export const CC_FRONT_EXEMPLE_3 = '/library/media/pictures/frontend/cc-front-exemple-3.jpg'
export const CC_FRONT_EXEMPLE_4 = '/library/media/pictures/frontend/cc-front-exemple-4.jpg'
export const CC_SUMMER_SOUTH_OF_FRANCE = '/library/media/pictures/frontend/cc-summer-southoffrance.jpg'
export const CHAMALLOW = '/library/media/pictures/frontend/chamallow.jpg'
export const CHILD = '/library/media/pictures/frontend/child.jpg'
export const CHILD_AND_CAMPER = '/library/media/pictures/frontend/child-and-camper.jpg'
export const CHILD_AND_CAMPER_2 = '/library/media/pictures/frontend/child-and-camper-2.jpg'
export const CHILD_AND_CAMPER_3 = '/library/media/pictures/frontend/child-and-camper-3.jpg'
export const CHILD_HOLIDAY = '/library/media/pictures/frontend/child-holiday.jpg'
export const COACHBUILT = '/library/media/pictures/frontend/coachbuilt.jpg'
export const COACHBUILT_AND_SEA = '/library/media/pictures/frontend/coachbuilt-and-sea.jpg'
export const COACHBUILT_OCEAN = '/library/media/pictures/frontend/coachbuilt-ocean.jpg'
export const COACHBUILT_OLDPEOPLE_BREAK = '/library/media/pictures/frontend/coachbuilt-oldpeople-break.jpg'
export const COMBI_3 = '/library/media/pictures/frontend/combi-3.jpg'
export const COMBI_AND_OCEAN = '/library/media/pictures/frontend/combi-and-ocean.jpg'
export const COMBI_AND_ROAD_1 = '/library/media/pictures/frontend/combi-and-road-1.jpg'
export const COMBI_AND_ROAD_2 = '/library/media/pictures/frontend/combi-and-road-2.jpg'
export const COMBI_BREAK_FEET_WINDOW = '/library/media/pictures/frontend/combi-break-feet-window.jpg'
export const COMBI_SEASIDE_SURFTRIP = '/library/media/pictures/frontend/combi-seaside-surftrip.jpg'
export const COMMUNITY = '/library/media/pictures/frontend/community.jpg'
export const CONVERTEDVAN = '/library/media/pictures/frontend/convertedvan.jpg'
export const CONVERTEDVAN_AND_MOUNTAIN = '/library/media/pictures/frontend/convertedvan-and-mountain.jpg'
export const CONVERTEDVAN_AND_MOUNTAIN_3 = '/library/media/pictures/frontend/convertedvan-and-mountain-3.jpg'
export const CONVERTEDVAN_AND_SEA_1 = '/library/media/pictures/frontend/convertedvan-and-sea-1.jpg'
export const CONVERTEDVAN_AND_SEA_2 = '/library/media/pictures/frontend/convertedvan-and-sea-2.jpg'
export const CONVERTEDVAN_BREAK_COMPUTER = '/library/media/pictures/frontend/convertedvan-break-computer.jpg'
export const CONVERTEDVAN_HARBOUR = '/library/media/pictures/frontend/convertedvan-harbour.jpg'
export const CONVERTEDVAN_MOUNTAIN_LAKE = '/library/media/pictures/frontend/convertedvan-mountain-lake.jpg'
export const CONVERTEDVAN_WOOD_FOREST = '/library/media/pictures/frontend/convertedvan-wood-forest.jpg'
export const DEALER = '/library/media/pictures/frontend/dealer.jpg'
export const ERROR_404 = '/library/media/pictures/frontend/erreur.jpg'
export const FRIENDS = '/library/media/pictures/frontend/friends.jpg'
export const HOLIDAY_AND_SEA_1 = '/library/media/pictures/frontend/holiday-and-sea-1.jpg'
export const HOLIDAY_AND_SEA_2 = '/library/media/pictures/frontend/holiday-and-sea-2.jpg'
export const HOLIDAY_AND_SEA_3 = '/library/media/pictures/frontend/holiday-and-sea-3.jpg'
export const HOLIDAY_AND_SEA_4 = '/library/media/pictures/frontend/holiday-and-sea-4.jpg'
export const KEYS = '/library/media/pictures/frontend/keys.jpg'
export const KEYS_2 = '/library/media/pictures/frontend/keys-2.jpg'
export const LANDSCAPE = '/library/media/pictures/frontend/landscape.jpg'
export const LANDSCAPE_2 = '/library/media/pictures/frontend/landscape-2.jpg'
export const LANDSCAPE_3 = '/library/media/pictures/frontend/landscape-3.jpg'
export const LOWPROFILE_AND_MONTAIN_1 = '/library/media/pictures/frontend/lowprofile-and-mountain-1.jpg'
export const LOWPROFILE_AND_MONTAIN_2 = '/library/media/pictures/frontend/lowprofile-and-mountain-2.jpg'
export const LOWPROFILE_AND_ROAD = '/library/media/pictures/frontend/lowprofile-and-road.jpg'
export const LOWPROFILE_AND_SEA = '/library/media/pictures/frontend/lowprofile-and-sea.jpg'
export const LOWPROFILE_FOREST = '/library/media/pictures/frontend/lowprofile-forest.jpg'
export const LOWPROFILE_OCEANSIDE = '/library/media/pictures/frontend/lowprofile-oceanside.jpg'
export const PET_AND_CHILD = '/library/media/pictures/frontend/pet-and-child.jpg'
export const PET_AND_COUPLE = '/library/media/pictures/frontend/pet-and-couple.jpg'
export const PET_AND_COUPLE_2 = '/library/media/pictures/frontend/pet-and-couple-2.jpg'
export const RADIO_CAMPING_1 = '/library/media/pictures/frontend/radio-camping-1.jpg'
export const RADIO_CAMPING_2 = '/library/media/pictures/frontend/radio-camping-2.jpg'
export const REMOTE_WORKING = '/library/media/pictures/frontend/remote-working.jpg'
export const SHAKE_HANDS = '/library/media/pictures/frontend/shake-hands.jpg'
export const SCREEN_APP_EN = '/library/media/pictures/frontend/screen-app-en.png'
export const SCREEN_APP_FR = '/library/media/pictures/frontend/screen-app-fr.png'
export const SCREEN_APP_DE = '/library/media/pictures/frontend/screen-app-de.png'
export const SCREEN_APP_CA = '/library/media/pictures/frontend/screen-app-ca.png'
export const SCREEN_APP_ES = '/library/media/pictures/frontend/screen-app-es.png'
export const SCREEN_APP_IT = '/library/media/pictures/frontend/screen-app-it.png'
export const SCREEN_APP_NL = '/library/media/pictures/frontend/screen-app-nl.png'
export const SCREEN_APP_PT = '/library/media/pictures/frontend/screen-app-pt.png'
export const STAFF_1 = '/library/media/pictures/frontend/staff-1.jpg'
export const STAFF_2 = '/library/media/pictures/frontend/staff-2.jpg'
export const STAFF_3 = '/library/media/pictures/frontend/staff-3.jpg'
export const STAFF_4 = '/library/media/pictures/frontend/staff-4.jpg'
export const STAFF_5 = '/library/media/pictures/frontend/staff-5.jpg'
export const STAFF_MEETING = '/library/media/pictures/frontend/staff-meeting.jpg'
export const SURFTRIP_1 = '/library/media/pictures/frontend/surftrip-1.jpg'
export const SURFTRIP_2 = '/library/media/pictures/frontend/surftrip-2.jpg'
export const TRAVELERS = '/library/media/pictures/frontend/travelers.jpg'
export const USER_AND_CAMPER_1 = '/library/media/pictures/frontend/user-and-camper-1.jpg'
export const USER_AND_CAMPER_2 = '/library/media/pictures/frontend/user-and-camper-2.jpg'
export const USER_AND_CAMPER_3 = '/library/media/pictures/frontend/user-and-camper-3.jpg'
export const USER_AND_COACHBUILT = '/library/media/pictures/frontend/user-and-coachbuilt.jpg'
export const USER_AND_CAMPER_KEY_1 = '/library/media/pictures/frontend/user-and-camper-key-1.jpg'
export const USER_AND_CAMPER_KEY_2 = '/library/media/pictures/frontend/user-and-camper-key-2.jpg'
export const USER_AND_CAMPERVAN = '/library/media/pictures/frontend/user-and-campervan.jpg'
export const USER_AND_COMBI = '/library/media/pictures/frontend/user-and-combi.jpg'
export const USER_AND_COMBI_1 = '/library/media/pictures/frontend/user-and-combi-1.jpg'
export const USER_AND_COMBI_2 = '/library/media/pictures/frontend/user-and-combi-2.jpg'
export const USER_AND_MOUNTAIN = '/library/media/pictures/frontend/user-and-mountain.jpg'
export const USER_AND_VAN = '/library/media/pictures/frontend/user-and-van.jpg'
export const USER_AND_VAN_2 = '/library/media/pictures/frontend/user-and-van-2.jpg'
export const USER_AND_VAN_3 = '/library/media/pictures/frontend/user-and-van-3.jpg'
export const USER_AND_VAN_4 = '/library/media/pictures/frontend/user-and-van-4.jpg'
export const USER_AND_SEA_1 = '/library/media/pictures/frontend/user-and-sea-1.jpg'
export const USER_AND_SEA_2 = '/library/media/pictures/frontend/user-and-sea-2.jpg'
export const USER_COFFEE = '/library/media/pictures/frontend/user-coffee.jpg'
export const USER_COFFEE_2 = '/library/media/pictures/frontend/user-coffee-2.jpg'
export const USER_COFFEE_3 = '/library/media/pictures/frontend/user-coffee-3.jpg'
export const USER_COFFEE_4 = '/library/media/pictures/frontend/user-coffee-4.jpg'
export const USER_HOLIDAY = '/library/media/pictures/frontend/user-holiday.jpg'
export const USER_HOLIDAY_2 = '/library/media/pictures/frontend/user-holiday-2.jpg'
export const USERS_AND_VAN = '/library/media/pictures/frontend/users-and-van.jpg'
export const VAN_AND_ROAD = '/library/media/pictures/frontend/van-and-road.jpg'
export const VAN_AND_SEA = '/library/media/pictures/frontend/van-and-sea.jpg'
export const VAN_AND_SKY = '/library/media/pictures/frontend/van-and-sky.jpg'
export const VAN_RED = '/library/media/pictures/frontend/van-red.jpg'
export const VAN_SAVANE = '/library/media/pictures/frontend/van-savane.jpg'
export const VOLKSWAGEN_1 = '/library/media/pictures/frontend/volkswagen-1.png'
export const VOLKSWAGEN_2 = '/library/media/pictures/frontend/volkswagen-2.jpg'
export const VOLKSWAGEN_3 = '/library/media/pictures/frontend/volkswagen-3.jpg'
export const VOLKSWAGEN_4 = '/library/media/pictures/frontend/volkswagen-4.jpg'
export const WOODWORK_VAN = '/library/media/pictures/frontend/woodwork-van.jpg'
export const SQUARE_KEYS = KEYS
export const SQUARE_TRAVELER = TRAVELERS
export const OPEN_GRAPH = '/library/media/pictures/frontend/sharing-preview.png'
export const SURF_PORTUGAL = '/library/media/pictures/frontend/poi-surf-portugal.jpg'

export const DRAWING_A_CLASS = '/library/media/pictures/frontend/drawing-camper-a-class.png'
export const DRAWING_COACHBUILT_BIKES = '/library/media/pictures/frontend/drawing-camper-coachbuilt.png'
export const DRAWING_T6_TOPUP = '/library/media/pictures/frontend/drawing-camper-vw-t6.png'
export const DRAWING_LOW_PROFILE_BIKES = '/library/media/pictures/frontend/drawing-camper-low-profile.png'
export const DRAWING_CONVERTED_VAN_SURF = '/library/media/pictures/frontend/drawing-camper-converted-van.png'
export const DRAWING_COMBI = '/library/media/pictures/frontend/drawing-camper-vw-t2.png'

export const OWNER_ONBOARDING_1 = '/library/media/pictures/frontend/owner-onboarding-1.jpeg'
export const OWNER_ONBOARDING_2 = '/library/media/pictures/frontend/owner-onboarding-2.jpeg'
export const FLEET_MANAGERS = '/library/media/pictures/frontend/fleet-managers.png'

export const PUSH_APPS_RENTAL_CONTRACT = '/library/media/pictures/frontend/push-to-rental-contract.png'
