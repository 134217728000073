import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { AvailabilitiesListItemResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { areIntervalsOverlapping, differenceInCalendarDays, isSameDay } from 'date-fns'

export function validateHoursConstraintsForDateRange(
  { start, end }: { start: Date, end: Date },
  availabilities: AvailabilitiesListItemResponse[],
  { booking_min_length }: { booking_min_length: number }): {
    forceDepartureMorning: boolean
    forceDepartureAfternoon: boolean
    forceArrivalMorning: boolean
    forceArrivalAfternoon: boolean
  } {
  // This function assume that the range (start, end) is valid regarding the camper availabilities and ad configuration.
  const { DEPARTURE_AFTERNOON, ARRIVAL_MORNING } = Constants.BOOKINGS.HOURS

  // If the selected time period is of the same number of day as the minimum allowed,
  // force the departure in the morning and the arrival in the afternoon so we actually have the correct number of day
  const selectedPeriodMinDays = availabilities
    .filter(
      ({ availability, date_from, date_to }) =>
        availability === Constants.VEHICLES.AVAILABILITY.AVAILABLE
        && areIntervalsOverlapping(
          { start, end },
          { start: setDateAsStartDate(new Date(date_from)), end: setDateAsEndDate(new Date(date_to)) },
        ),
    )
    .reduce((acc, { bkg_days_min }) => Math.max(acc, bkg_days_min ?? 0), booking_min_length)

  if (selectedPeriodMinDays === differenceInCalendarDays(end, start) + 1) {
    return {
      forceDepartureMorning: true,
      forceDepartureAfternoon: false,
      forceArrivalMorning: false,
      forceArrivalAfternoon: true,
    }
  }

  // Check for edges - day where the vehicle is only unavaibile for half a day
  const edgesDays = availabilities.filter(
    ({ availability, hour_from, hour_to }) =>
      availability === Constants.VEHICLES.AVAILABILITY.NOT_AVAILABLE && (hour_from === DEPARTURE_AFTERNOON || hour_to === ARRIVAL_MORNING),
  )

  // If the start date is on a day where the vehicle is unavaible starting from the afternoon - is that case, the arrival must happen in the morning
  const hasArrivalEdgeConstraint = edgesDays
    .filter(({ hour_from }) => hour_from === DEPARTURE_AFTERNOON)
    .some(({ date_from }) => isSameDay(setDateAsStartDate(new Date(date_from)), end))

  // If the start date is on a day where the vehicle is unavailable until the afternoon - is that case, the departure must happen in the afternoon.
  const hasDepartureEdgeConstraint = edgesDays
    .filter(({ hour_to }) => hour_to === ARRIVAL_MORNING)
    .some(({ date_to }) => isSameDay(setDateAsStartDate(new Date(date_to)), start))

  return {
    forceDepartureMorning: false,
    forceDepartureAfternoon: hasDepartureEdgeConstraint,
    forceArrivalMorning: hasArrivalEdgeConstraint,
    forceArrivalAfternoon: false,
  }
}
