import { differenceInYears } from 'date-fns'

export const isUserInValidAgeRange = ({ birthday, dateFrom, dateTo, insuranceMinDriverAge, insuranceMaxDriverAge }: { birthday: Date | null, dateFrom: Date, dateTo: Date, insuranceMinDriverAge: number | null, insuranceMaxDriverAge: number | null }) => {
  if (!birthday) {
    return false
  }
  const userAgeDeparture = differenceInYears(dateFrom, birthday)
  const userAgeArrival = differenceInYears(dateTo, birthday)
  return (insuranceMinDriverAge === null || userAgeDeparture >= insuranceMinDriverAge) && (insuranceMaxDriverAge === null || userAgeArrival <= insuranceMaxDriverAge)
}
