import { setHeader } from 'h3'

export default defineNuxtRouteMiddleware(() => {
  if (!import.meta.server) {
    return
  }
  const event = useRequestEvent()
  if (event) {
    setHeader(event, 'x-ysc-version', useRuntimeConfig().public.app.version)
  }
})
