import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { TRUSTED_SHOP_TOO_MANY, TRUSTED_SHOP_INVALID_ID, TRUSTED_SHOP_UNKNOWN } from './errorFingerprints'

export class YscTrustedShopError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    const { apiStatus, apiResponseErrorData } = args
    if (apiStatus === 429) {
      this.fingerprint = TRUSTED_SHOP_TOO_MANY
      return
    }

    if (typeof apiResponseErrorData === 'string' && apiResponseErrorData.match('TSID is invalid')) {
      this.fingerprint = TRUSTED_SHOP_INVALID_ID
      return
    }

    this.fingerprint = TRUSTED_SHOP_UNKNOWN
  }
}
