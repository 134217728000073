<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import type { FaqSearchItemResponse } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  activeTab?: 'guest' | 'owner'
}

const props = withDefaults(defineProps<Props>(), {
  activeTab: 'guest',
})

const { t } = useI18n()
const { localeProperties: { yscCode } } = useLocaleProperties()

const { routing } = storeToRefs(useRoutingStore())
const { toggleSideHelp } = useMenusStore()
const { sideHelpIsOpen } = storeToRefs(useMenusStore())

const { isOwner } = storeToRefs(useUserStore())

const faq = ref<Record<'guest' | 'owner', FaqSearchItemResponse[]>>({
  guest: [],
  owner: [],
})
const faqResults = ref<Record<'guest' | 'owner', FaqSearchItemResponse[]>>({
  guest: [],
  owner: [],
})

const q = ref('')
const loading = ref(false)
const selectedArticle = ref<FaqSearchItemResponse | null>(null)
const localActiveTab = ref(props.activeTab)
if (isOwner.value) {
  localActiveTab.value = 'owner'
}

const helpType = {
  GUEST: 'guest',
  OWNER: 'owner',
}
const route = useRoute()
const tabs = ([
  {
    to: useGetRouteWith({ query: { ...route.query, help: helpType.GUEST } }),
    key: helpType.GUEST,
    label: t('components.app_help.tabs.guest'),
  },
  {
    to: useGetRouteWith({ query: { ...route.query, help: helpType.OWNER } }),
    key: helpType.OWNER,
    label: t('commons.owner'),
  },
])

watch(() => route.query.help, async () => {
  if (route.query.help && Object.values(helpType).includes(route.query.help.toString())) {
    // @ts-expect-error we test if the query is type of localActiveTab on the precedent if
    localActiveTab.value = route.query.help
    q.value = ''
    await fetchFaq(localActiveTab.value)
  }
})

const searchArticles = computed(() => faqResults.value[localActiveTab.value])
const articles = computed(() => searchArticles.value.length ? searchArticles.value : faq.value[localActiveTab.value].slice(0, 7))
const open = computed({
  get() {
    return sideHelpIsOpen.value
  },
  set() {
    toggleSideHelp()
  },
})

watch(open, async () => {
  if (!open.value) {
    await navigateTo({
      query: {
        ...route.query,
        help: undefined,
      },
    })
    return
  }
  fetchFaq(localActiveTab.value)
})

watch(q, (q) => {
  searchFaq(q)
})

const fetchFaq = async (tab: 'guest' | 'owner') => {
  if (!open.value || faq.value[tab].length) {
    return
  }

  loading.value = true
  const { $api } = useYscApi()
  faq.value[tab] = await $api.faq.getTopic(tab, {})
  loading.value = false
}

const searchFaq = useDebounceFn(async function (q) {
  if (!q) {
    faqResults.value[localActiveTab.value] = []
    return
  }

  loading.value = true
  const { $api } = useYscApi()
  faqResults.value[localActiveTab.value] = await $api.faq.lookFor({
    q,
    lang: yscCode,
    target: localActiveTab.value,
  })
  loading.value = false
}, 250)

const { y } = useWindowScroll()
const { height } = useWindowSize()

const show = computed(() => y.value > height.value / 2)

const { getTranslatedLink } = useGetTranslatedLink()
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div class="hidden lg:fixed lg:bottom-0 lg:right-0 lg:z-50 lg:m-4 lg:block">
      <transition :name="show ? 'slide-up' : 'slide-down'">
        <button
          v-show="show"
          variant="primary"
          class="btn btn-primary"
          @click="open = true"
        >
          <YscIconsLifeRing class="-my-1 mr-2 h-5 w-5" />
          {{ $t('commons.help') }}
        </button>
      </transition>
    </div>

    <YscPanel
      v-model="open"
    >
      <transition
        :name="selectedArticle ? 'slide-left-nav' : 'slide-right-nav'"
        mode="out-in"
      >
        <div
          v-if="selectedArticle"
          key="1"
        >
          <div class="flex h-16 items-center justify-between px-4 shadow">
            <div class="flex items-center">
              <button
                type="button"
                class="btn btn-link btn-icon mr-2"
                @click="selectedArticle = null"
              >
                <YscIconsDelete class="h-6 w-6 overflow-hidden rounded-full" />
              </button>
              <h3 class="text-base font-semibold">
                {{ selectedArticle.text }}
              </h3>
            </div>
          </div>

          <div
            class="overflow-y-scroll"
            style="height: calc(100vh - 140px)"
          >
            <div
              class="prose px-4 py-8"
              v-html="selectedArticle.answer"
            />
          </div>
        </div>

        <div
          v-else
          key="2"
        >
          <div class="flex h-16 items-center justify-between px-4">
            <h3 class="text-xl font-semibold">
              {{ $t('commons.help') }}
            </h3>
            <button
              type="button"
              class="btn btn-link btn-icon"
              @click="open = !open"
            >
              <YscIconsDelete class="h-5 w-5" />
            </button>
          </div>
          <YscMenuTabs
            :aria-label-tabs="$t('commons.help')"
            :tabs="tabs"
            :default-active="localActiveTab"
            is-full-size
            class="border-b border-gray-200 text-center w-full"
            @change-tab="localActiveTab = $event"
          />

          <div
            class="overflow-y-scroll"
            style="height: calc(100vh - 180px)"
          >
            <div class="px-6 pb-4 pt-6">
              <YscInputKeyboard
                id="search"
                v-model="q"
                type="text"
                :placeholder="$t('components.app_help.search.placeholder')"
                full-width
                :loading="loading"
                autofocus
                required
              >
                {{ $t('components.app_help.search.label') }}
              </YscInputKeyboard>
            </div>

            <div class="px-6 py-4">
              <p class="mb-2 text-sm font-semibold uppercase">
                {{ searchArticles.length ? $t('components.app_help.results') : $t('components.app_help.recommended') }}
              </p>
              <ul>
                <li v-show="!searchArticles.length">
                  <NuxtLink
                    :to="{ name: 'how-yescapa-works' }"
                    class="link link-secondary py-2 text-left"
                  >
                    {{ getTranslatedLink('how-yescapa-works') }}
                  </NuxtLink>
                </li>
                <li
                  v-for="(article, index) in articles"
                  :key="index"
                >
                  <button
                    class="link link-secondary py-2 text-left"
                    @click="selectedArticle = article"
                  >
                    {{ article.text }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>

      <div class="absolute bottom-0 left-0 right-0 flex w-full items-center justify-center p-4">
        <NuxtLink
          :to="routing?.faq_home"
          class="btn btn-primary"
        >
          {{ $t('components.app_help.link') }}
        </NuxtLink>
      </div>
    </YscPanel>
  </div>
</template>
