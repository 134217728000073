import { USER_PROPERTY_IS_STAFF_PARAM,
  USER_PROPERTY_IS_PRO_PARAM,
  USER_PROPERTY_IS_GUEST_PARAM,
  USER_PROPERTY_BOOKING_AS_GUEST_PARAM,
  USER_PROPERTY_BOOKING_AS_OWNER_PARAM,
  USER_PROPERTY_EMAIL_PARAM,
  USER_PROPERTY_PHONE_PARAM } from '@/utils/analytics/auth'

export const useSetAnalyticsUser = () => {
  const { $gtm } = useNuxtApp()
  const { user } = storeToRefs(useUserStore())

  const setAnalyticsUser = () => {
    const userValue = toValue(user)
    if (!userValue) {
      return
    }
    $gtm('set', 'user_properties', {
      [USER_PROPERTY_IS_STAFF_PARAM]: userValue.is_staff,
      [USER_PROPERTY_IS_PRO_PARAM]: userValue.professional,
      [USER_PROPERTY_IS_GUEST_PARAM]: !userValue.is_owner,
      [USER_PROPERTY_BOOKING_AS_GUEST_PARAM]: userValue.booking_as_guest,
      [USER_PROPERTY_BOOKING_AS_OWNER_PARAM]: userValue.booking_as_owner,
      [USER_PROPERTY_EMAIL_PARAM]: userValue.email,
      [USER_PROPERTY_PHONE_PARAM]: userValue.phone,
    })
  }

  return { setAnalyticsUser }
}
