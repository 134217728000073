export const useSeoDefault = ({ imagePath = undefined }: { imagePath?: string } = {}) => {
  const { public: { facebook: { appId } } } = useRuntimeConfig()
  const { localeProperties: { yscCode } } = useLocaleProperties()
  const route = useRoute()
  const { origin } = useRequestURL()

  useHead({
    meta: [
      getMetaProperty({ property: 'og:site_name', content: 'Yescapa' }),
      getMetaProperty({ property: 'og:type', content: 'website' }),
      getMetaProperty({ property: 'fb:app_id', content: appId }),
      getMetaProperty({ property: 'og:url', content: new URL(route.fullPath, origin).href }),
      getMetaProperty({ property: 'og:locale', content: yscCode }),
      getHeadMetaName({ name: 'twitter:card', content: 'summary_large_image' }),
      getHeadMetaName({ name: 'twitter:site', content: '@Yescapa' }),
      getHeadMetaName({ name: 'twitter:creator', content: 'Yescapa' }),
    ],
  })
  useSeoText()
  useSeoImage({ imagePath })
}
