import type { BookingRequestPriceResponse, PaymentSummaryPayload, PaymentSummaryResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { AllNullable } from '~/types/commons'

export const useCheckoutStore = defineStore('checkout', () => {
  const infos = ref<AllNullable<PaymentSummaryResponse>>({
    first_name: null,
    last_name: null,
    street: null,
    city: null,
    zipcode: null,
    country_of_residence: null,
    nationality: null,
    payments: null,
    country: null,
  })

  const prices = ref<BookingRequestPriceResponse>()

  const { $api } = useYscApi()

  const fetchCheckoutInfos = async ({ id }: { id: number | string }) => {
    infos.value = await $api.requests.getPaymentSummary(id)
  }

  const updateCheckoutInfos = async ({ id, payload, sendPaymentsId }: { id: number | string, payload: Partial<PaymentSummaryPayload>, sendPaymentsId?: boolean }) => {
    const data = { ...infos.value, ...payload }
    infos.value = data
    // We want to have the payments in the payload only when the G select the payment method
    if (!sendPaymentsId) {
      data.payments = null
    }
    await $api.requests.postPaymentSummary(id, data as PaymentSummaryPayload)
  }

  const hasEncounteredRentalCoverError = ref(false)

  return {
    infos,
    prices,
    fetchCheckoutInfos,
    updateCheckoutInfos,
    hasEncounteredRentalCoverError,
  }
})
