<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

interface Props {
  title?: string
}

withDefaults(defineProps<Props>(), {
  title: undefined,
})

const open = defineModel<boolean>({ default: false })
const panel = ref()
onClickOutside(panel, () => {
  open.value = false
})
</script>

<template>
  <aside>
    <transition name="fade">
      <div
        v-if="open"
        class="fixed inset-0 z-[100] h-screen w-screen bg-black opacity-25"
      />
    </transition>
    <transition name="slide-right">
      <div
        v-if="open"
        ref="panel"
        class="fixed bottom-0 right-0 top-0 z-[100] h-screen min-w-[320px] max-w-[400px] overflow-hidden bg-white shadow-lg"
      >
        <slot />
      </div>
    </transition>
  </aside>
</template>
