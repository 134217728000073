<script setup lang="ts">
import type { FieldError } from '~/utils/error/YscApiError'

defineProps<{
  error: Toastable
}>()

const { t } = useI18n()
const getTranslatedContent = (field: FieldError['label'] | FieldError['description']) => {
  const { text, needTranslation, translationParams } = field ?? {}
  if (!text) {
    return
  }

  if (translationParams) {
    return t(text, translationParams)
  }
  if (needTranslation) {
    return t(text)
  }

  return text
}
</script>

<template>
  <div
    :class="{
      'bg-red-500 text-white': error.type === 'error',
      'bg-green-500 text-white': error.type === 'success',
    }"
    class="p-4 rounded "
  >
    <p
      v-for="field in error.fields"
      :key="field.label.text"
    >
      <span class="font-semibold block">{{ getTranslatedContent(field.label) }}</span>
      {{ getTranslatedContent(field.description) }}
    </p>
  </div>
</template>
