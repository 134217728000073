import { YscError } from './YscError'
import { YscNetworkError } from './YscNetworkError'
import { YscStaffError } from './YscStaffError'
import { YscAxeptioError } from './YscAxeptioError'
import { YscContentError } from './YscContentError'
import { YscGoogleMapsError } from './YscGoogleMapsError'
import { YscGeolocationError } from './YscGeolocationError'

const errorClassMap = {
  YscNetworkError,
  YscError,
  YscStaffError,
  YscAxeptioError,
  YscContentError,
  YscGoogleMapsError,
  YscGeolocationError,
} as const

export type YscErrorClassName = keyof typeof errorClassMap

const isYscErrorClassName = (name: string): name is YscErrorClassName => name in errorClassMap

export function getMiscellaneousErrorClass(errorName: string) {
  if (isYscErrorClassName(errorName)) {
    return errorClassMap[errorName]
  }

  return YscError
}
