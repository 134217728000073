export const useWithErrorManagerHandling = () => {
  const { $errorManager } = useErrorManager()

  async function withErrorManagerHandling<ReturnType>(fn: () => ReturnType, errorName: string, rethrow: true): Promise<ReturnType>
  async function withErrorManagerHandling<ReturnType>(fn: () => ReturnType, errorName: string, rethrow: false): Promise<ReturnType | undefined>
  async function withErrorManagerHandling<ReturnType>(fn: () => ReturnType, errorName: string, rethrow: boolean = false) {
    try {
      return await fn()
    }
    catch (e) {
      let qualifiedError = e
      if (e instanceof Error) {
        qualifiedError = $errorManager({ e, name: errorName })
      }

      if (rethrow) {
        throw qualifiedError
      }
    }
  }

  return { withErrorManagerHandling }
}
