import type { BookingPurposeSummary, Camper } from '@yescapa-dev/ysc-api-js/legacy'
import { skipHydrate } from 'pinia'

export type BookingRequestFunnelForm = {
  purpose: BookingPurposeSummary | null
  travelers: number | null
  details: string | null
  countries: string[]
  phone: string | null
  profession?: string
  borned_on: Date | null
  insurance_id: number | null
  secondDriver: boolean
  options: Record<string, boolean>
  camperId: number | undefined
}

export const useBookingRequestFunnelStore = defineStore('bookingRequestFunnel', () => {
  const { user } = storeToRefs(useUserStore())

  const { camper, vehicle } = storeToRefs(useCamperStore())

  const initForm = (): BookingRequestFunnelForm => ({
    camperId: camper.value?.id,
    purpose: null,
    travelers: null,
    details: null,
    countries: vehicle.value?.location.country ? [vehicle.value?.location.country] : [],
    phone: user.value?.phone ?? null,
    profession: undefined,
    borned_on: user.value?.borned_on ? new Date(user.value?.borned_on) : null,
    insurance_id: null,
    secondDriver: false,
    options: camper.value ? Object.fromEntries(camper.value.options.map<[string, boolean]>(option => ([option.option_name, false]))) : {},
  })

  const resetFormForCamperIfNeeded = () => {
    if (form.value.camperId !== camper.value?.id) {
      form.value = initForm()
    }
  }

  // We proxy the sessionStorage reference manually, in order to avoid hydration mismatch when loading the page from the server.
  const form = ref(initForm())
  const storedForm = useSessionStorage<BookingRequestFunnelForm>('f-booking-request-form', initForm(), { mergeDefaults: true })

  const selectedOptionsList = computed(() => Object.entries(form.value.options)
    .filter(([_key, value]) => value)
    .map(([key]) => camper.value?.options.find(opt => opt.option_name === key))
    .filter((option): option is Camper['options'][number] => option !== undefined),
  )

  const restoreStoredFormIfApplicable = () => {
    if (storedForm.value.camperId === form.value.camperId) {
      form.value = {
        ...storedForm.value,
        options: { ...storedForm.value.options },
        countries: [...storedForm.value.countries],
      }
    }
  }
  watch(form, () => saveFormToStorage(), { deep: true })

  const saveFormToStorage = () => {
    storedForm.value = {
      ...form.value,
      options: { ...form.value.options },
      countries: [...form.value.countries],
    }
  }

  const isContinueButtonActionProcessing = ref(false)

  return {
    form: skipHydrate(form),
    resetFormForCamperIfNeeded,
    restoreStoredFormIfApplicable,
    saveFormToStorage,
    selectedOptionsList,
    isContinueButtonActionProcessing,
  }
})
