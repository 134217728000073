<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

export interface Props {
  open?: boolean
  direction: 'top' | 'bottom'
  anchor?: 'left' | 'right'
  fixedWidth?: string | null
  displayButton?: boolean
  ellipsis?: boolean
  contentOffset: string | null
  isLight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  direction: 'top',
  anchor: 'left',
  fixedWidth: undefined,
  displayButton: false,
  ellipsis: false,
  contentOffset: null,
  isLight: false,
  open: undefined,
})

const emit = defineEmits<{
  toggleOpen: []
  close: []
}>()

const contentClass = computed(() => [
  props.direction === 'bottom' ? 'bottom-auto top-full' : 'top-auto bottom-full mb-px',
  props.anchor === 'left' ? 'left-auto' : 'right-0',
  props.fixedWidth,
  props.contentOffset,
])

const isControlledByParent = computed(() => props.open !== undefined)

const localOpen = ref(false)
watchEffect(() => {
  localOpen.value = !!props.open
})

const toggleOpen = () => {
  if (isControlledByParent.value) {
    emit('toggleOpen')
    return
  }
  localOpen.value = !localOpen.value
}

const close = () => {
  if (isControlledByParent.value) {
    emit('close')
    return
  }
  localOpen.value = false
}

const dropdown = ref(null)
onClickOutside(dropdown, () => close())
</script>

<template>
  <div
    ref="dropdown"
    :class="props.direction === 'bottom' ? 'flex-col' : 'flex-col-reverse'"
    class="relative flex items-center"
  >
    <button
      type="button"
      :class="[{ 'btn btn-tertiary': props.displayButton }, [props.isLight ? 'text-white hover:text-gray-50' : 'text-black']]"
      class="inline-flex items-center space-x-2 font-normal"
      @click="toggleOpen"
    >
      <slot name="label" />
      <slot name="icon">
        <YscIconsEllipsis
          v-if="ellipsis"
          :class="{ 'text-gray-400': localOpen }"
          class="h-4 w-4 rotate-90"
        />
        <YscIconsArrowBottom
          v-else
          :class="{ 'rotate-180': localOpen }"
          class="h-5 w-5 transition-transform duration-200"
        />
      </slot>
    </button>

    <div
      v-if="$slots.default"
      v-show="localOpen"
      :class="contentClass"
      class="absolute z-[60] overflow-hidden rounded border border-gray-100 bg-white shadow-2xl"
    >
      <slot name="default" />
    </div>
  </div>
</template>
