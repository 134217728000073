import type { KmOption } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationNormalized } from '#vue-router'

export const getBookingRequestQueryParameters = (route: RouteLocationNormalized) => {
  const { date_from, date_to, hour_from, hour_to, kilometers } = route.query

  const dateFromAsString = queryValueToString(date_from)
  const dateToAsString = queryValueToString(date_to)

  const hourTo = queryValueToNumber(hour_to)
  const hourFrom = queryValueToNumber(hour_from)

  const dateFrom = dateFromAsString ? new Date(dateFromAsString) : null
  const dateTo = dateToAsString ? new Date(dateToAsString) : null

  return {
    dateFrom,
    dateTo,
    hourFrom,
    hourTo,
    kilometers: queryValueToNumber(kilometers) as KmOption | null,
  }
}
