import type { ScopeContext as SentryScopeContext } from '@sentry/types'
import { UNKNOWN } from './errorFingerprints'

export interface YscErrorParams {
  message?: string
  locale?: string
  route?: string
  name?: string
}
export class YscError extends Error {
  name: string
  _fingerprint: string
  date: string
  locale: string | undefined
  route: string | undefined

  constructor({ message = '', locale, route, name = 'YscError' }: YscErrorParams = {}) {
    super(message)
    this.name = name

    this._fingerprint = UNKNOWN
    this.date = new Date().toUTCString()
    this.locale = locale
    this.route = route

    // v8 browsers only
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, YscError)
    }
  }

  get fingerprint() {
    return this._fingerprint
  }

  set fingerprint(value) {
    this._fingerprint = value
  }

  /**
   * Special getters to enrich context in sentry interface
   */

  get sentryAdditionalData(): SentryScopeContext['extra'] {
    return {
      name: this.name,
      date: this.date,
      locale: this.locale,
      route: this.route,
    }
  }

  get sentryTags(): SentryScopeContext['tags'] {
    return {
      // Adding fingerprint to the tag makes it searchable in sentry's interface
      fingerprint: this.fingerprint,
    }
  }
}
