import type { LocationQuery, LocationQueryValue } from '#vue-router'

export const DJANGO_MATCH = /\d{2}-\d{2}-\d{4}/
export const djangoDateToIso = (date: string) => date.split('-').reverse().join('-')

export interface SanitizeQueryAllowedValues {
  [Key: string]: {
    type: 'number'
    values?: number[]
    min?: number
    max?: number
  } | {
    type: 'date'
  } | {
    type: 'array'
    allowedArrayValues: LocationQueryValue[]
  }
}

export const sanitizeQuery = (query: LocationQuery = {}, allowedValues: SanitizeQueryAllowedValues) => {
  let mustRedirect: boolean = false
  const validationErrorLogs: string[] = []
  const nextQuery: LocationQuery = {}
  for (const [key, value] of Object.entries(query)) {
    if (!allowedValues[key]) {
      nextQuery[key] = value
      continue
    }

    const allowedValueDefinition = allowedValues[key]
    switch (allowedValueDefinition.type) {
      case 'number':
        if (
          !Number.isNaN(Number(value))
          && (allowedValueDefinition.min !== undefined ? Number(value) >= allowedValueDefinition.min : true)
          && (allowedValueDefinition.max !== undefined ? Number(value) <= allowedValueDefinition.max : true)
          && (allowedValueDefinition.values ? allowedValueDefinition.values.includes(Number(value)) : true)
        ) {
          nextQuery[key] = value
        }
        else {
          validationErrorLogs.push(`sanitizeQuery: Invalid value for key "${key}"`)
          mustRedirect = true
        }
        break
      case 'array':
        if (value) {
          if (!Array.isArray(allowedValueDefinition.allowedArrayValues)) {
            validationErrorLogs.push(`sanitizeQuery: invalid value for key "${key}", expected array`)
            mustRedirect = true
          }
          else {
            const validValues: LocationQueryValue[] = []
            for (const v of value) {
              if (allowedValueDefinition.allowedArrayValues.includes(v)) {
                ; validValues.push(v)
              }
              else {
                validationErrorLogs.push(`sanitizeQuery: Invalid value for key "${key}"`)
                mustRedirect = true
              }
            }
            nextQuery[key] = validValues
          }
        }
        break
      case 'date':
        if (value?.length === 10) {
          if (value.toString().match(/\d{4}-\d{2}-\d{2}/)) {
            nextQuery[key] = value
          }
          else {
            const djangoMatch = DJANGO_MATCH.exec(value.toString())
            if (Array.isArray(djangoMatch) && djangoMatch[0] === value) {
              nextQuery[key] = djangoDateToIso(djangoMatch[0])
              mustRedirect = true
              validationErrorLogs.push(`sanitizeQuery: "${key}" is using django date format`)
            }
          }
        }
        else {
          mustRedirect = true
          validationErrorLogs.push(`sanitizeQuery: Invalid value for key "${key}"`)
        }
        break
    }
  }

  // must be range
  if ((nextQuery.date_from && !nextQuery.date_to) || (nextQuery.date_to && !nextQuery.date_from)) {
    delete nextQuery.date_from
    delete nextQuery.date_to
    mustRedirect = true
    validationErrorLogs.push('sanitizeQuery: one of date_from, date_to is missing, removing date range')
  }

  const dateTo = queryValueToString(nextQuery.date_to)
  const dateFrom = queryValueToString(nextQuery.date_from)
  // from must be before to
  if (
    dateFrom
    && dateTo
    && (+new Date(dateFrom) > +new Date(dateTo)
    || isNaN(+new Date(dateFrom))
    || isNaN(+new Date(dateTo)))
  ) {
    delete nextQuery.date_from
    delete nextQuery.date_to
    validationErrorLogs.push('sanitizeQuery: Invalid range for keys "date_from", "date_to"')
    mustRedirect = true
  }
  return {
    nextQuery,
    mustRedirect,
    validationErrorLogs,
  }
}
