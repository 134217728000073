import { joinURL } from 'ufo'
import type { RouteLocationNormalized } from 'vue-router'

// improved verions of https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/plugins/chunk-reload.client.ts
export default defineNuxtPlugin({
  name: 'ysc:chunk-reload',
  parallel: true,
  enforce: 'pre',
  setup() {
    const { app: { baseURL } } = useRuntimeConfig()
    const { hook } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const chunkErrors = new Set()

    router.beforeEach(() => {
      chunkErrors.clear()
    })

    // https://github.com/nuxt/nuxt/issues/23612#issuecomment-1805131115
    hook('app:error', (error) => {
      const reoload_error_list = [
        'error loading dynamically imported module',
        'Importing a module script failed',
        'Failed to fetch dynamically imported module',
      ]
      if (reoload_error_list.some(message => error.message?.includes(message))) {
        reloadAppAtPath(route)
      }
    })

    hook('app:chunkError', ({ error }) => {
      chunkErrors.add(error)
      reloadAppAtPath(route)
    })

    function reloadAppAtPath(to: RouteLocationNormalized) {
      const isHash = 'href' in to && (to.href as string)[0] === '#'
      const path = isHash ? baseURL + (to as any).href : joinURL(baseURL, to.fullPath)
      reloadNuxtApp({ path, persistState: true })
    }

    hook('app:manifest:update', () => {
      router.beforeResolve(reloadAppAtPath)
    })

    router.onError((error, to) => {
      if (chunkErrors.has(error)) {
        reloadAppAtPath(to)
      }
    })
  },
})
