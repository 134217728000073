/// <reference types="@types/google.maps" />
import { YSC_GOOGLE_MAPS_ERROR } from '~/utils/error/YscErrorClasses'

declare global {
  interface Window {
    gm_authFailure: () => void
    initAutocomplete: () => void
  }
}

export default defineNuxtPlugin({
  name: 'ysc:map',
  dependsOn: ['ysc:error-manager'],
  parallel: true,
  setup() {
    const { $errorManager, $config } = useNuxtApp()
    let isLoaded = false,
      isAdded = false,
      queue: {
        fn: (...args: HTMLInputElement[]) => void
        arguments: HTMLInputElement[]
      }[] = []

    function addAutocompleteScript() {
      const script = document.createElement('script')
      window.gm_authFailure = () => {
        $errorManager({ e: new Error('gm_authFailure'), name: YSC_GOOGLE_MAPS_ERROR })
      }
      script.src = `https://maps.googleapis.com/maps/api/js?key=${$config.public.googleMaps.key}&libraries=places&callback=initAutocomplete`
      script.async = true
      isAdded = true
      window.initAutocomplete = initAutocomplete
      document.head.appendChild(script)
    }

    function initAutocomplete() {
      isLoaded = true
      queue.forEach((item) => {
        if (typeof item.fn === 'function') {
          item.fn(...item.arguments)
        }
      })
      queue = []
    }

    function setupAutocomplete(input: HTMLInputElement, ...args: HTMLInputElement[]) {
      if (!isAdded) {
        addAutocompleteScript()
      }

      if (!isLoaded) {
        queue.push({ fn: setupAutocomplete, arguments: [input, ...args] })
        return
      }
      const autoComplete = new window.google.maps.places.Autocomplete(input)
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace()
        if (!place.geometry) {
          return
        }

        const streetNumber = getTypeData(place, 'street_number')
        const route = getTypeData(place, 'route')
        const detail = {
          street: streetNumber && route ? `${streetNumber} ${route}` : null,
          zipcode: getTypeData(place, 'postal_code'),
          city: getTypeData(place, 'locality'),
          country: getTypeData(place, 'country'),
          latitude: place.geometry.location?.lat(),
          longitude: place.geometry.location?.lng(),
        }
        if (!detail.country) {
          detail.country = getTypeData(place, 'administrative_area_level_2')
        }

        if (!detail.city) {
          detail.city = getTypeData(place, 'administrative_area_level_3')
        }

        input.dispatchEvent(new CustomEvent('changed', { detail }))
      })
    }

    function getTypeData(place: google.maps.places.PlaceResult, type: string) {
      const component = place.address_components?.find(ac => ac.types.includes(type))
      return component ? component.short_name : null
    }

    return { provide: { maps: setupAutocomplete } }
  },
})
