/* EVENTS */
export const AUTH_LOGIN_EVENT = 'login'

/* PARAMS */
export const AUTH_LOGIN_METHOD_PARAM = 'method'
export const USER_PROPERTY_IS_STAFF_PARAM = 'is_staff'
export const USER_PROPERTY_IS_PRO_PARAM = 'is_pro'
export const USER_PROPERTY_IS_GUEST_PARAM = 'is_guest_interface'
export const USER_PROPERTY_BOOKING_AS_GUEST_PARAM = 'guest_nb_booking_done'
export const USER_PROPERTY_BOOKING_AS_OWNER_PARAM = 'owner_nb_booking_done'
export const USER_PROPERTY_EMAIL_PARAM = 'email'
export const USER_PROPERTY_PHONE_PARAM = 'phone'
