import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { AXEPTIO_UNKNOWN } from './errorFingerprints'

export class YscAxeptioError extends YscError {
  constructor(args: YscErrorParams) {
    super(args)

    this.fingerprint = AXEPTIO_UNKNOWN
  }
}
