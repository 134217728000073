import merge from 'lodash.merge'
import type { RouteRecordName, LocationQuery, RouteParams } from '#vue-router'

export function useGetRouteWith({
  name,
  query,
  params,
  hash,
}: {
  name?: RouteRecordName
  query?: LocationQuery
  params?: RouteParams
  hash?: string
}) {
  const route = useRoute()

  const r = merge(
    {},
    {
      name: route.name,
      params: route.params,
      query: route.query,
      hash: route.hash,
    },
    { name, params, query, hash },
  )
  return removeEntresWithNullValues(r)
}
