import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { NETWORK } from './errorFingerprints'

export class YscNetworkError extends YscError {
  constructor(args: YscErrorParams) {
    super(args)
    this.fingerprint = NETWORK
  }
}
