<script setup lang="ts">
const { public: { app: { favicon } } } = useRuntimeConfig()
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `/${favicon || 'icon.png'}`,
    },
  ],
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AppToastGroup />
  </div>
</template>
