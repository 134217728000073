import { ACCESS_TOKEN, REFRESH_ACCESS_TOKEN } from '~/constants/auth'

export function useAuthCookies() {
  const accessTokenCookie = useCookie(ACCESS_TOKEN, {
    path: '/',
    secure: true,
    sameSite: 'lax',
    maxAge: 60 * 2,
  })
  const refreshAccessTokenCookie = useCookie(REFRESH_ACCESS_TOKEN, {
    path: '/',
    secure: true,
    sameSite: 'lax',
    maxAge: 60 * 60 * 24 * 365,
  })

  return {
    accessTokenCookie,
    refreshAccessTokenCookie,
  }
}
