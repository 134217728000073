import type { Organization, Product } from 'schema-dts'

export const useMetaDefaultHead = () => {
  const { localeProperties: { yscCode } } = useLocaleProperties()
  const { seoImageHref } = useSeoImageHref()
  const image = seoImageHref()
  const { $i18n: { t } } = useNuxtApp()
  const description = t('commons.meta.description')
  const { stats } = useStatsStore()
  const { origin } = useRequestURL()
  const { routing } = useRoutingStore()
  const { referential } = useReferentialStore()

  type RoutingSameAs = 'twitter' | 'facebook' | 'instagram' | 'pinterest' | 'youtube'
  const routingSameAs: RoutingSameAs[] = ['twitter', 'facebook', 'instagram', 'pinterest', 'youtube']
  const sameAs = routingSameAs.reduce<string[]>((acc, cur) => {
    if (routing && cur in routing) {
      acc.push(routing[cur])
    }
    return acc
  }, [])

  const yescapaOrganizationMicrodata: Organization = {
    '@type': 'Organization',
    'name': 'Yescapa',
    'url': origin,
    'logo': '/icon.png',
  }

  if (sameAs.length) {
    yescapaOrganizationMicrodata.sameAs = sameAs
  }

  if (referential?.phone_number) {
    yescapaOrganizationMicrodata.contactPoint = [
      {
        '@type': 'ContactPoint',
        'telephone': referential.phone_number,
        'contactType': 'customer service',
      },
    ]
  }

  const yescapaProductMicrodata: Product = {
    '@type': 'Product',
    'name': 'Yescapa',
    image,
    description,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'bestRating': 5,
      'worstRating': 0,
      'ratingValue': stats.average,
      'reviewCount': stats.count,
    },
  }

  useHead({
    htmlAttrs: {
      lang: yscCode,
    },
    templateParams: {
      site: {
        name: 'Yescapa',
      },
      separator: '-',
    },
    titleTemplate: '%s %separator %site.name',
    script: [
      getJsonLDScript(yescapaProductMicrodata),
      getJsonLDScript(yescapaOrganizationMicrodata),
    ],
  })
}
