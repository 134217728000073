import type { VehicleResponse, VehicleTypeInteger } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { isString } from '@yescapa-dev/ysc-api-js/modern'

export const useStoredVehicle = () => {
  const sanitizeStoredVehicle = (unclean: any): Partial<VehicleResponse> => {
    const sanitized: Partial<VehicleResponse> = {}
    const {
      id,
      type,
      seatbelts,
      beds,
      registration,
      registration_country,
      date,
      gvw,
      height,
      value,
      location: {
        city,
        country,
        latitude,
        longitude,
        street,
        zipcode,
      },
    } = unclean ?? {
      location: {},
    }

    // step vehicle-type
    const parsedId = parseInt(id)
    if (!Number.isNaN(parsedId)) {
      sanitized.id = parsedId
    }

    const parsedType = parseInt(type)
    const typeValues = Object.values(Constants.PRODUCTS.TYPES)
    if (!Number.isNaN(parsedType) && typeValues.includes(parsedType as VehicleTypeInteger)) {
      sanitized.type = parsedType as VehicleTypeInteger
    }

    const parsedSeatbelts = parseInt(seatbelts)
    if (!Number.isNaN(parsedSeatbelts) && parsedSeatbelts >= 0 && parsedSeatbelts <= 9) {
      // @ts-expect-error improve typing
      sanitized.seatbelts = parsedSeatbelts
    }

    const parsedBeds = parseInt(beds)
    if (!Number.isNaN(parsedBeds) && parsedBeds >= 0 && parsedBeds <= 9) {
      // @ts-expect-error improve typing
      sanitized.beds = parsedBeds
    }

    // step vehicle-properties
    if (isString(registration)) {
      sanitized.registration = registration
    }

    if (isString(registration_country)) {
      sanitized.registration_country = registration_country
    }

    if (isString(date)) {
      sanitized.date = date
    }

    const parsedGvw = parseInt(gvw)
    if (!Number.isNaN(parsedGvw)) {
      sanitized.gvw = parsedGvw
    }

    const parsedHeight = parseInt(height)
    if (!Number.isNaN(parsedHeight)) {
      sanitized.height = parsedHeight
    }

    const parsedValue = parseInt(value)
    if (!Number.isNaN(parsedValue)) {
      sanitized.value = parsedValue
    }

    // step vehicle-location
    const parsedLatitude = parseFloat(latitude)
    const parsedLongitude = parseFloat(longitude)
    if (isString(city) && isString(country) && isString(street) && isString(zipcode) && !Number.isNaN(parsedLatitude) && !Number.isNaN(parsedLongitude)) {
      sanitized.location ??= {
        city,
        country,
        street,
        zipcode,
        latitude: parsedLatitude,
        longitude: parsedLongitude,
      }
    }

    // further steps are handled by API

    return sanitized
  }

  const storedVehicle = useLocalStorage<Partial<VehicleResponse> | null>(
    'ysc-form-onboarding', // Key was updated following breaking api change on location ids.
    null,
    {
      mergeDefaults: true,
      serializer: {
        read: (v: any) => {
          try {
            return sanitizeStoredVehicle(JSON.parse(v))
          }
          catch (e) {
            return null
          }
        },
        write: (v: any) => JSON.stringify(v),
      },
    },
  )

  return { storedVehicle, sanitizeStoredVehicle }
}
