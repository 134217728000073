import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { CERTIFY_PHONE_BAD_CODE, CERTIFY_PHONE_UNKNOWN } from './errorFingerprints'

export class YscApiCertifyPhoneError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    const { apiMethod, apiStatus } = args

    if (apiMethod === 'post' && apiStatus === 400) {
      this.fingerprint = CERTIFY_PHONE_BAD_CODE
      this.defaultDescription = { text: 'dashboard.account.certify_phone.form.error', needTranslation: true }
      return
    }

    this.fingerprint = CERTIFY_PHONE_UNKNOWN
  }
}
