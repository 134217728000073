import type { AllNullable } from '~/types/commons'

// Assertion needed because typescript cannot convert AllNullable<Type> to Type easily.
// It checks an aray of keys and remove `null` type for each Type['key']
export function assertAllNullableTypeIsType<Type>(obj: AllNullable<Type>, ...keys: (keyof Type)[]): asserts obj is { [Property in keyof Type]: Property extends keyof typeof obj ? NonNullable<Type[Property]> : Type[Property] } {
  const missingKey = keys.find(k => obj[k] === null || obj[k] === undefined)
  if (missingKey) {
    throw new Error(`missing required property ${String(missingKey)}`)
  }
}
