import type { LocationQuery } from '#vue-router'

export const sanitizeCamperRequestQuery = (query: LocationQuery) => {
  const allowedValues: SanitizeQueryAllowedValues = {
    date_from: {
      type: 'date',
    },
    date_to: {
      type: 'date',
    },
    hour_from: {
      type: 'number',
      values: [9, 14],
    },
    hour_to: {
      type: 'number',
      values: [12, 20],
    },
    kilometers: {
      type: 'number',
      values: [0, 100, 200],
    },
  }

  return sanitizeQuery(query, allowedValues)
}
