export const useMenusStore = defineStore('menus', () => {
  const sideMenuIsOpen = ref(false)
  const sideHelpIsOpen = ref(false)

  const toggleSideMenu = () => {
    sideMenuIsOpen.value = !sideMenuIsOpen.value
  }

  const toggleSideHelp = () => {
    sideHelpIsOpen.value = !sideHelpIsOpen.value
  }

  return {
    sideMenuIsOpen,
    sideHelpIsOpen,
    toggleSideHelp,
    toggleSideMenu,
  }
})
