import type { AllBookingsGuest, BookingOwnerDetails, BookingOwnerListItem, BookingsGuestDetails } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { differenceInCalendarDays } from 'date-fns'

export function getTravelStateEnhanced(travel: BookingOwnerDetails | BookingsGuestDetails | BookingOwnerListItem | AllBookingsGuest) {
  if (travel.state === Constants.BOOKINGS.STATUS.TO_COME) {
    // API never set DONE state and travel remains in TO_COME state
    if (travel.meta_state === Constants.BOOKINGS.LISTING_STATE.ARCHIVED) {
      return Constants.BOOKINGS.STATUS.DONE
    }

    if (differenceInCalendarDays(new Date(travel.date_time_to), new Date()) < 0) {
      return STATUS_ENHANCED.FINISHED
    }

    if (differenceInCalendarDays(new Date(travel.date_time_from), new Date()) <= 0) {
      return STATUS_ENHANCED.ONGOING
    }
  }

  return travel.state
}
