export const useStatsStore = defineStore('stats', () => {
  const stats = ref({
    count: 0,
    average: 0,
  })

  const fetchStats = async () => {
    if (stats.value?.count) {
      return
    }

    const { localeProperties: { yscCode } } = useLocaleProperties()
    stats.value = await $fetch('/api/internals/referential/stats', {
      query: {
        lang: yscCode,
      },
    })
  }

  return {
    stats,
    fetchStats,
  }
})
