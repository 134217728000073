import { YscError } from './YscError'
import {
  GEOLOCATION_PERMISSION_DENIED,
  GEOLOCATION_POSITION_UNAVAILABLE,
  GEOLOCATION_TIMEOUT,
  GEOLOCATION_UNKNOWN,
} from './errorFingerprints'

export class YscGeolocationError extends YscError {
  constructor(args) {
    super(args)

    const { code } = args

    if (code === 1) {
      this.fingerprint = GEOLOCATION_PERMISSION_DENIED
      this.fields = [
        {
          label: this.defaultLabel,
          description: { text: 'data.vehicle.location.geolocalize_error_1', needTranslation: true },
        },
      ]
      return
    }

    if (code === 2) {
      this.fingerprint = GEOLOCATION_POSITION_UNAVAILABLE
      this.fields = [
        {
          label: this.defaultLabel,
          description: { text: 'data.vehicle.location.geolocalize_error_2', needTranslation: true },
        },
      ]
      return
    }

    if (code === 3) {
      this.fingerprint = GEOLOCATION_TIMEOUT
      this.fields = [
        {
          label: this.defaultLabel,
          description: { text: 'data.vehicle.location.geolocalize_error_3', needTranslation: true },
        },
      ]
      return
    }

    this.fingerprint = GEOLOCATION_UNKNOWN
  }
}
