export function useDropdownPuppet() {
  const open = ref(false)
  const onToggleOpen = () => {
    open.value = !open.value
  }
  const onClose = () => {
    open.value = false
  }
  return { open, onToggleOpen, onClose }
}
