import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { BOOKING_GUEST_INVALID_COUPON_CODE, BOOKING_GUEST_PRICE, BOOKING_GUEST_UNKNOWN, BOOKING_GUEST_VALIDATE, RENTAL_COVER_SERVICE_UNAVAILABLE } from './errorFingerprints'
import { isErrorCausedByRentalCoverUnavailability } from './isErrorCausedByRentalCoverUnavailability'

const regexBookingGuestValidate = /^\/v4\/bookings-guest\/\d+\/validate\//
const regexBookingGuestPrice = /^\/3\/my-requests\/\d+\/prices\//
export class YscApiBookingGuestError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    let fingerprint = BOOKING_GUEST_UNKNOWN
    if (this.apiMethod === 'patch' && this.apiEndpoint && regexBookingGuestValidate.test(this.apiEndpoint)) {
      fingerprint = BOOKING_GUEST_VALIDATE
    }

    if (this.apiMethod === 'get' && this.apiEndpoint && regexBookingGuestPrice.test(this.apiEndpoint)) {
      fingerprint = BOOKING_GUEST_PRICE
      if (isErrorCausedByRentalCoverUnavailability(this)) {
        fingerprint = RENTAL_COVER_SERVICE_UNAVAILABLE
      }
      if (this.apiStatus === 400 && Object.prototype.hasOwnProperty.call(this.apiResponseErrorData, 'coupon_code')) {
        fingerprint = BOOKING_GUEST_INVALID_COUPON_CODE
      }
    }

    this.fingerprint = fingerprint
  }
}
