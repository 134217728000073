import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { CAMPER_NOT_FOUND, CAMPER_UNKNOWN, CAMPER_ID_NOT_A_NUMBER, CAMPER_DELETED } from './errorFingerprints'

const regexGetCamperEndpoint = /^\/3\/products\/\d+\/?/
export class YscApiCamperError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    let fingerprint = CAMPER_UNKNOWN
    if (this.apiMethod === 'get' && this.apiEndpoint && regexGetCamperEndpoint.test(this.apiEndpoint)) {
      if (this.apiStatus === 404) {
        fingerprint = CAMPER_NOT_FOUND
      }
      else if (this.apiStatus) {
        fingerprint = CAMPER_DELETED
      }
    }
    else if (this.apiMethod === 'get' && this.apiStatus === 404 && this.apiEndpoint === '/3/products/NaN/') {
      fingerprint = CAMPER_ID_NOT_A_NUMBER
    }

    this.fingerprint = fingerprint
  }
}
