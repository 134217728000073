<script setup lang="ts">
const { toggleSideHelp } = useMenusStore()
const { open, onToggleOpen, onClose } = useDropdownPuppet()

const onToggleSideHelp = () => {
  onClose()
  toggleSideHelp()
}
const { getTranslatedLink } = useGetTranslatedLink()
const { t } = useI18n()
const groupLink = [
  {
    to: { name: 'travelers-hit-the-road' },
    title: t('components.app_menu_help_dropdown.hit_the_road.title'),
    description: t('components.app_menu_help_dropdown.hit_the_road.description'),
    icon: resolveComponent('YscIconsInternational'),
    bgColorClass: 'bg-pink-50',
    iconColorClass: 'text-pink-700',
    hoverColorClass: 'focus-visible:ring-pink-300 hover:bg-pink-50 focus-visible:bg-pink-50',
  },
  {
    to: { name: 'rent-my-motorhome' },
    title: t('components.app_menu_help_dropdown.rent_my_motorhome.title'),
    description: t('components.app_menu_help_dropdown.rent_my_motorhome.description'),
    icon: resolveComponent('YscIconsVehicle'),
    bgColorClass: 'bg-gray-50',
    iconColorClass: 'text-current',
    hoverColorClass: 'focus-visible:ring-gray-300 hover:bg-gray-50 focus-visible:bg-gray-50',
  },
  {
    to: { name: 'how-yescapa-works' },
    title: getTranslatedLink('how-yescapa-works'),
    description: getTranslatedLink('discover-process'),
    icon: resolveComponent('YscIconsYescapa'),
    bgColorClass: 'bg-peacock-50',
    iconColorClass: 'text-peacock-700',
    hoverColorClass: 'focus-visible:ring-peacock-300 hover:bg-peacock-50 focus-visible:bg-peacock-50',
  },
]

const hideHelpFeatureFlag = useFeatureFlag('hideHelp')

const { routing } = storeToRefs(useRoutingStore())
const helpCenterLink = computed(() => {
  if (hideHelpFeatureFlag) {
    return routing.value?.faq_home
  }
  return undefined
})

const helpCenterComponentProps = computed(() => {
  if (hideHelpFeatureFlag) {
    return {
      is: resolveComponent('NuxtLink'),
      to: helpCenterLink.value,
      external: true,
    }
  }
  else {
    return {
      is: 'button',
      role: 'button',
    }
  }
})
</script>

<template>
  <YscDropdown
    :open="open"
    direction="bottom"
    anchor="right"
    fixed-width="w-max"
    content-offset="mt-10"
    @toggle-open="onToggleOpen"
    @close="onClose"
  >
    <template #label>
      <div class="flex h-[30px] items-center">
        <span class="block">{{ $t('commons.help') }}</span>
      </div>
    </template>

    <template #default>
      <div class="p-2">
        <nav>
          <NuxtLink
            v-for="itemLink in groupLink"
            :key="itemLink.to.name"
            :to="itemLink.to"
            :class="itemLink.hoverColorClass"
            class="group relative flex w-full items-center rounded p-2 pr-14 font-light transition-colors focus-visible:z-10 focus-visible:ring-2 focus-visible:ring-offset-2"
            @click="onClose"
          >
            <span
              :class="itemLink.bgColorClass"
              class="mr-4 rounded p-4"
            >
              <component
                :is="itemLink.icon"
                :class="itemLink.iconColorClass"
                class="h-6 w-6"
              />
            </span>
            <div class="flex flex-col justify-between text-left">
              <span class="block font-semibold text-peacock-700">{{ itemLink.title }}</span>
              <span class="block text-sm text-gray-500">{{ itemLink.description }}</span>
            </div>
            <YscIconsArrowRightLong class="absolute right-4 hidden h-6 w-6 transition-opacity group-hover:block" />
          </NuxtLink>
        </nav>

        <component
          v-bind="helpCenterComponentProps"
          :is="helpCenterComponentProps.is"
          class="focus-visible:ring-yellow-300 group relative flex w-full items-center rounded p-2 pr-14 font-light transition-colors hover:bg-yellow-50 focus-visible:z-10 focus-visible:bg-yellow-50 focus-visible:ring-2 focus-visible:ring-offset-2"
          @click="onToggleSideHelp"
        >
          <span class="mr-4 rounded bg-yellow-50 p-4">
            <YscIconsLifeRing class="h-6 w-6 text-yellow-700" />
          </span>
          <div class="flex flex-col justify-between text-left">
            <span class="block font-semibold text-peacock-700">{{ getTranslatedLink('help-center') }}</span>
            <span class="block text-sm text-gray-500">{{ getTranslatedLink('find-answers') }}</span>
          </div>
          <YscIconsArrowRightLong class="opacity absolute right-4 hidden h-6 w-6 text-gray-current transition group-hover:block" />
        </component>
      </div>
    </template>
  </YscDropdown>
</template>
