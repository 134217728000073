<script setup lang="ts">
import { YSC_AXEPTIO_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  container?: 'container' | 'container-dashboard' | 'container-search'
  isLight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  container: 'container',
  isLight: false,
})

const { routing } = storeToRefs(useRoutingStore())
const { referential } = storeToRefs(useReferentialStore())
const { trustedShopData } = storeToRefs(useTrustedShopStore())

const { t } = useI18n()
const { getTranslatedLink } = useGetTranslatedLink()

const links = [
  { to: routing.value?.blog, text: t('commons.links.blog') },
  { to: routing.value?.contact, text: t('commons.links.contact') },
  { to: routing.value?.team, text: t('commons.links.team') },
  { to: 'https://www.welcometothejungle.com/fr/companies/yescapa', text: t('commons.links.jobs') },
  { to: routing.value?.press, text: t('commons.links.press') },
  { to: routing.value?.partners, text: t('commons.links.partners') },
  { to: { name: 'terms' }, text: getTranslatedLink('terms') },
  { to: { name: 'privacy-confidentiality' }, text: t('commons.privacy') },
].filter(({ to }) => to)

const socialLinks = [
  { to: routing.value?.instagram, text: 'Instagram', icon: resolveComponent('YscIconsInstagram') },
  { to: routing.value?.youtube, text: 'Youtube', icon: resolveComponent('YscIconsYoutube') },
  { to: routing.value?.twitter, text: 'X', icon: resolveComponent('YscIconsX') },
  { to: routing.value?.pinterest, text: 'Pinterest', icon: resolveComponent('YscIconsPinterest') },
  { to: routing.value?.facebook, text: 'Facebook', icon: resolveComponent('YscIconsFacebook') },
].filter(({ to }) => to)

const guestLinks = [
  { to: { name: 'how-yescapa-works' }, text: getTranslatedLink('how-yescapa-works') },
  { to: { name: 's' }, text: getTranslatedLink('s') },
  { to: { name: 'travelers-rv-beginner' }, text: getTranslatedLink('travelers-rv-beginner') },
  { to: { name: 'community-all-reviews' }, text: t('commons.links.all_reviews') },
  { to: routing.value?.gift_card_presentation, text: t('commons.links.gift_card_presentation') },
  { to: routing.value?.faq_topic_list_guest, text: t('commons.links.faq_topic_list_guest') },
].filter(({ to }) => to)

const ownerLinks = [
  { to: { name: 'onboarding-step' }, text: getTranslatedLink('onboarding-step') },
  { to: routing.value?.ambassador, text: t('commons.links.ambassador') },
  { to: routing.value?.contract, text: t('commons.links.contract') },
  { to: routing.value?.list_insurances, text: t('commons.links.list_insurances') },
  { to: routing.value?.list_assistances, text: t('commons.links.list_assistances') },
  { to: routing.value?.faq_topic_list_owner, text: t('commons.links.faq_topic_list_owner') },
].filter(({ to }) => to)

const payments = computed(() => {
  const paymentMethod: Record<string, string[]> = {
    card: ['mastercard', 'visa'],
    card_2: ['cb2x'],
    cred_4: ['floa4x'],
    ho_vou: ['ancv'],
    sofort: ['klarna'],
    paypal: ['paypal'],
    klarna: ['klarna'],
    ideal: ['ideal'],
    mbway: ['mbway'],
  }
  const ease: string[] = []
  const options: string[] = []

  referential.value?.payment_type_available?.forEach((value) => {
    const mappedValue = paymentMethod[value]
    if (!mappedValue || mappedValue.find(v => options.includes(v))) {
      return
    }
    if (['cb2x', 'floa4x'].find(v => mappedValue.includes(v))) {
      ease.push(...mappedValue)
    }
    else {
      options.push(...mappedValue)
    }
  })

  return {
    ease,
    options,
  }
})

const assets = getDynamicAssetsMap(import.meta.glob('~/assets/img/payments/*.svg', { eager: true })) as Record<string, string>

const openCookieModal = () => {
  try {
    (window as any).openAxeptioCookies()
  }
  catch (e) {
    if (e instanceof Error) {
      const { $errorManager } = useErrorManager()
      $errorManager({ e, name: YSC_AXEPTIO_ERROR })
    }
  }
}
</script>

<template>
  <footer>
    <div
      v-if="!props.isLight"
      class="bg-gray-50"
    >
      <div
        :class="props.container"
        class="space-y-12 py-12 text-gray-500 sm:grid sm:grid-flow-row sm:grid-cols-2 sm:gap-12 sm:space-y-0 md:grid-cols-3 lg:grid-cols-4 lg:py-20 xl:grid-cols-5"
      >
        <div class="space-y-6 sm:col-span-full lg:col-span-2 xl:col-span-3">
          <div>
            <AppLogo />
            <p class="mt-4 max-w-prose">
              {{ $t('components.app_footer.about.description') }}
            </p>
          </div>

          <NuxtLink
            to="https://www.trustedshops.com/buyerrating/info_X0E12AB2B3F09D5849974CBB113C40DE5.html"
            title="yescapa.fr custom reviews"
            class="app-footer-link"
            no-prefetch
          >
            {{
              $t('components.app_footer.about.reviews.trustedshops_dynamic', {
                grade_number: trustedShopData.overallMark,
                total_number: 5,
                count_number: trustedShopData.totalReviewCount,
              })
            }}
          </NuxtLink>

          <nav
            :aria-label="$t('commons.navigation.social_links')"
            class="flex space-x-4"
          >
            <NuxtLink
              v-for="(link, i) in socialLinks"
              :key="`footer_social_${i}`"
              :to="link.to"
              class="block rounded-full bg-gray-200 p-2 text-gray-500 hover:text-black focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              no-prefetch
            >
              <span class="sr-only">{{ link.text }}</span>
              <component
                :is="link.icon"
                class="block h-5 w-5"
              />
            </NuxtLink>
          </nav>
        </div>

        <div>
          <p class="text-sm font-semibold uppercase">
            {{ $t('components.app_footer.guest_links.title') }}
          </p>

          <nav
            :aria-label="$t('components.app_footer.guest_links.title')"
            class="mt-4"
          >
            <div
              v-for="(link, i) in guestLinks"
              :key="`footer_guest_${i}`"
            >
              <NuxtLink
                :to="link.to"
                class="app-footer-link"
                no-prefetch
              >
                {{ link.text }}
              </NuxtLink>
            </div>
          </nav>
        </div>

        <div>
          <p class="text-sm font-semibold uppercase">
            {{ $t('components.app_footer.owner_links.title') }}
          </p>

          <nav
            :aria-label="$t('components.app_footer.owner_links.title')"
            class="mt-4"
          >
            <div
              v-for="(link, i) in ownerLinks"
              :key="`footer_owner_${i}`"
            >
              <NuxtLink
                :to="link.to"
                class="app-footer-link"
                no-prefetch
              >
                {{ link.text }}
              </NuxtLink>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div
      v-if="!props.isLight"
      class="bg-gray-100"
    >
      <div
        :class="props.container"
        class="flex flex-wrap items-start gap-10 py-10 text-gray-500 xl:gap-x-40"
      >
        <div class="flex flex-wrap items-center gap-10 xl:gap-20">
          <div v-if="payments.options.length">
            <p class="font-semibold">
              {{ $t('components.app_footer.payment.means') }}
            </p>
            <div class="mt-2 flex flex-wrap gap-2">
              <img
                v-for="file in payments.options"
                :key="file"
                :src="assets[file]"
                class="rounded border"
                :alt="file"
                loading="lazy"
                width="48"
                height="32"
              >
            </div>
          </div>

          <div v-if="payments.ease.length">
            <p class="font-semibold">
              {{ $t('components.app_footer.payment.ease') }}
            </p>

            <div class="mt-2 flex flex-wrap gap-2">
              <img
                v-for="file in payments.ease"
                :key="file"
                class="rounded border"
                :src="assets[file]"
                :alt="file"
                loading="lazy"
                width="48"
                height="32"
              >
            </div>
          </div>
        </div>

        <nav
          :aria-label="$t('commons.navigation.mobile_apps_links')"
          class="col-span-full flex flex-wrap items-center gap-10 xl:gap-20"
        >
          <NuxtLink
            :to="{ name: 'get-apps' }"
            class="flex space-x-3 hover:text-black focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            no-prefetch
          >
            <YscIconsApple class="h-12 w-12" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t('components.app_footer.apps.apple_html')" />
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'get-apps' }"
            class="flex space-x-3 hover:text-black focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            no-prefetch
          >
            <YscIconsPlayStore class="h-12 w-12" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t('components.app_footer.apps.play_store_html')" />
          </NuxtLink>
        </nav>
      </div>
    </div>

    <div
      :class="props.container"
      class="flex items-center justify-between py-6 text-gray-500"
    >
      <div class="flex flex-col-reverse flex-wrap items-center lg:flex-row lg:space-x-12">
        <p class="mt-4 sm:mt-0">
          &copy; {{ new Date().getFullYear() }} Yescapa
        </p>
        <nav
          :aria-label="$t('commons.navigation.secondary_links')"
          class="flex flex-wrap items-center justify-center sm:justify-start"
        >
          <div
            v-for="(link, i) in links"
            :key="`link_${i}`"
            class="after:px-2 after:content-['·']"
          >
            <NuxtLink
              :to="link.to"
              class="app-footer-link"
              no-prefetch
            >
              {{ link.text }}
            </NuxtLink>
          </div>
          <div>
            <button
              class="app-footer-link"
              @click="openCookieModal"
            >
              {{ $t('commons.links.cookies') }}
            </button>
          </div>
        </nav>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.app-footer-link {
  @apply inline-block py-2;
  @apply hover:underline;
  @apply focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2;
}
</style>
