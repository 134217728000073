<script setup lang="ts">
const props = defineProps({

  error: Object,
})

const handleError = () => {
  clearError({ redirect: '/s' })
}

useMetaDefaultHead()
useSeoDefault()

const { t } = useI18n()
const title = computed(() => `${props?.error?.statusCode} - ${t('pages.error.404.title')}`)
useSeoText({ title })
const isDev = import.meta.dev
</script>

<template>
  <div>
    <AppHeaderContainer class="py-2.5 md:py-5">
      <template #end>
        <AppHeaderMenu show-app-link />
      </template>
    </AppHeaderContainer>
    <main>
      <AppPageSection class="bg-peacock-50">
        <div class="flex flex-col gap-12 lg:flex-row">
          <img
            src="~/assets/img/vehicles/t6-beach.svg"
            class="mx-auto max-w-sm"
            :alt="$t('pages.error.404.title')"
          >
          <div>
            <template v-if="isDev && error?.statusCode === 500">
              <pre>{{ error.message }}</pre>
            </template>
            <template v-else>
              <div class="prose">
                <h1>{{ $t('pages.error.404.title') }}</h1>
                <p>
                  {{ $t('pages.error.404.message_content_1') }}
                </p>

                <p>
                  {{ $t('pages.error.404.message_content_2') }}
                </p>
              </div>
            </template>
            <div class="mt-6">
              <button
                class="btn btn-primary"
                @click="handleError"
              >
                {{ $t('pages.error.404.link_label') }}
              </button>
            </div>
          </div>
        </div>
      </AppPageSection>
    </main>

    <AppFooter />

    <ClientOnly v-if="!useFeatureFlag('hideHelp')">
      <AppHelp />
    </ClientOnly>
  </div>
</template>
