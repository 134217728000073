import type { SearchProductsQueryParameters } from '@yescapa-dev/ysc-api-js/legacy'
import type { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import type { FieldError } from './error/YscApiError'

export const paginatedResultFactory = () => ({
  count: 0,
  results: [],
})

export const brandManufacturerFactory = () => ({
  id: null,
  name: null,
  model: null,
})

export interface SearchFilters extends Omit<SearchProductsQueryParameters, 'min_price' | 'max_price' | 'seatbelts' | 'beds' | 'owner_types' | 'types' | 'longitude' | 'latitude' | 'radius' | 'date_from' | 'date_to' | 'licence_type' | 'review_average__gte' | 'marketing_partnership'> {
  min_price: Required<SearchProductsQueryParameters>['min_price'] | null
  max_price: Required<SearchProductsQueryParameters>['max_price'] | null
  seatbelts: Required<SearchProductsQueryParameters>['seatbelts'] | null
  beds: Required<SearchProductsQueryParameters>['beds'] | null
  owner_types: Required<SearchProductsQueryParameters>['owner_types'] | null
  types: number[]
  longitude: Required<SearchProductsQueryParameters>['longitude'] | null
  latitude: Required<SearchProductsQueryParameters>['latitude'] | null
  radius: Required<SearchProductsQueryParameters>['radius'] | null
  date_from: Required<SearchProductsQueryParameters>['date_from'] | null
  date_to: Required<SearchProductsQueryParameters>['date_to'] | null
  licence_type: Required<SearchProductsQueryParameters>['licence_type'] | null
  review_average__gte: Required<SearchProductsQueryParameters>['review_average__gte'] | null
  marketing_partnership: Required<SearchProductsQueryParameters>['marketing_partnership'] | null
  equipments: string[]
  where: string | null
  postgres: boolean
  searchViewMap: null
}

export const initialSearchFiltersFactory = (): SearchFilters => ({
  min_price: null,
  max_price: null,
  seatbelts: null,
  beds: null,
  discount_weekly: false,
  discount_early: false,
  km_unlimited: false,
  international: false,
  vehicle_height: false,
  less_than_five: false,
  automatic_gear: false,
  pets: false,
  is_ambassador: false,
  winter_tire: false,
  winter_chain: false,
  owner_types: null,
  instant_booking: false,
  disabled_access: false,
  equipments: [],
  types: [],
  longitude: null,
  latitude: null,
  radius: null,
  where: null,
  date_from: null,
  date_to: null,
  licence_type: null,
  review_average__gte: null,
  postgres: false,
  searchViewMap: null,
  marketing_partnership: null,
  isofix: false,
  smoking: undefined,
})

export const PERIOD_TYPE_PRICE = 'periodPrice'
export const PERIOD_TYPE_BOOKING = 'periodBooking'
export const PERIOD_TYPE_UNAVAILABLE = 'periodUnavailable'
export const PERIOD_TYPE_SELECTION = 'periodSelection'

export const STATUS_ENHANCED = {
  FINISHED: 'FINISHED',
  ONGOING: 'ONGOING',
}

export const STATUS_SUMMARY = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  WARNING: 'WARNING',
  DONE: 'DONE',
}

export type AvailableLocaleCodes = typeof LOCALE_CODES[keyof typeof LOCALE_CODES]
export type AvailableTopLevelLocalesCodes = 'ca' | 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'pt'

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][]

declare global {
  interface Window {
    dataLayer: any
  }
}

export interface Toastable {
  timestamp: number
  type: 'success' | 'error'
  fields: FieldError[]
}
