export const useSetSentryUser = () => {
  const { $sentry } = useNuxtApp()
  const { user } = storeToRefs(useUserStore())

  const setSentryUser = () => {
    const userValue = toValue(user)
    if (!userValue) {
      return
    }
    $sentry.setUser({
      ip_address: '{{auto}}',
      id: userValue.id,
      email: userValue.email,
      is_active: userValue.is_active,
      is_owner: userValue.is_owner,
      is_staff: userValue.is_staff,
    })
  }

  return { setSentryUser }
}
