import { YscApiNewsletterError } from '~/utils/error/YscApiNewsletterError'

export function useHandleNewsletterApiError() {
  const { $i18n: { locale } } = useNuxtApp()
  const report = useErrorReport()
  const route = useRoute()

  return ({ e }: { e: Error & { code?: string, apiURL?: string, email?: string, listIds?: any } }) => {
    const { code, apiURL, email, listIds } = e
    const yscError = new YscApiNewsletterError({
      locale: toValue(locale),
      route: route?.fullPath,
      code,
      apiURL,
      email,
      listIds,
    })

    report(yscError)

    return yscError
  }
}
