import { YSC_TRUSTED_SHOP_ERROR } from '~/utils/error/YscErrorClasses'

export const useTrustedShopStore = defineStore('trustedShop', () => {
  const trustedShopData = ref({
    totalReviewCount: 0,
    overallMark: 0,
  })
  const { $errorManager } = useErrorManager()
  const fetchTrustedShopData = async () => {
    if (trustedShopData.value.totalReviewCount) {
      return
    }

    try {
      const { totalReviewCount, overallMark } = await $fetch('/api/internals/trustedshop')
      trustedShopData.value = { totalReviewCount, overallMark }
    }
    catch (e) {
      if (e instanceof Error) {
        $errorManager({ e, name: YSC_TRUSTED_SHOP_ERROR })
      }
    }
  }

  return {
    fetchTrustedShopData,
    trustedShopData,
  }
})
