import type { Breadcrumb } from '@sentry/types'
import { api } from '@yescapa-dev/ysc-api-js/legacy'
import { YscApiConsumer } from '@yescapa-dev/ysc-api-js/modern'
import axios, { isAxiosError } from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { $Fetch } from 'ofetch'
import { YscError } from '~/utils/error/YscError'
import { YSC_API_AUTH_ERROR, YSC_NETWORK_ERROR } from '~/utils/error/YscErrorClasses'
import { AXIOS_INTERCEPTOR_UNDEFINED_ERROR } from '~/utils/error/errorFingerprints'

// This plugin needs to be registered AFTER the axios and i18n plugins
export default defineNuxtPlugin({
  name: 'ysc:api',
  dependsOn: ['ysc:sentry', 'ysc:error-manager', 'i18n:plugin'],
  parallel: true,
  setup() {
    const { public: { api: { rental: { url, key } } } } = useRuntimeConfig()
    const {
      $i18n: { locale },
      $errorManager,
      $sentry,
    } = useNuxtApp()

    const userStore = useUserStore()

    const { refreshAccessTokenCookie, accessTokenCookie } = useAuthCookies()

    const instance = axios.create({
      baseURL: url,
      headers: {
        'X-API-KEY': key,
        'Accept-Language': toValue(locale),
      },
    })

    const client = {
      $request: async <T, D>(config: AxiosRequestConfig<D>) => (await instance.request<T>(config)).data,
    }

    const yscApi = api(client)

    const toBearer = (token: string) => `Bearer ${token}`

    /*
   * Add authorization header to the api instance.
   * Only useful for the fetchMandatoryData cycles.
   */
    const setInstanceAuthorization = ({ token = null }: { token?: string | null } = {}) => {
      if (token) {
        instance.defaults.headers.common.Authorization = toBearer(token)
      }
      else {
        delete instance.defaults.headers.common.Authorization
      }
    }

    const getAuthorization = () => instance.defaults.headers.common.Authorization || accessTokenCookie.value

    const setRefreshAlreadyFailed = ({ reset = false } = {}) => {
      if (reset) {
        delete instance.defaults.headers.common['X-ALREADY-FAILED']
        return
      }
      instance.defaults.headers.common['X-ALREADY-FAILED'] = true
    }

    /*
   * helper method to detect if client needs to refresh before any request.
   * in server side, a refresh is performed before any requests and it sets a special header if failed.
   */
    const shouldRefresh = ({ url }: { url: string }) => {
      if (instance.defaults.headers.common['X-ALREADY-FAILED']) {
        return false
      }

      const refreshAccessToken = refreshAccessTokenCookie.value
      return !getAuthorization() && refreshAccessToken && !url.startsWith('/oauth/token')
    }

    /*
   * If access_token is expired before a request store pending requests and process them later
   */

    let isRefreshing = false,
      pendingRequests: {
        resolve: ({ token }: { token: string }) => void
        reject: (reason: any) => void
      }[] = []
    const processPendingRequests = ({ error, token }: { error?: any, token: string }) => {
      pendingRequests.forEach((request) => {
        if (error) {
          request.reject(error)
        }
        else {
          request.resolve({ token })
        }
      })
      pendingRequests = []
    }

    // Enrich each request with token if passible
    instance.interceptors.request.use(async (config) => {
      // Quick'n'dirty: remove cookie from server request otherwise API can authenticate with sessionid
      if (config.headers.cookie) {
        delete config.headers.cookie
      }

      if (shouldRefresh({ url: config.url ?? '' })) {
        if (isRefreshing) {
          // Pause parralel requests
          return new Promise<{ token: string }>((resolve, reject) => {
            pendingRequests.push({ resolve, reject })
          }).then(({ token }: { token: string }) => {
            config.headers.Authorization = toBearer(token)
            return config
          })
        }

        isRefreshing = true
        if (!refreshAccessTokenCookie.value) {
          const e = new Error('No refresh token')
          throw $errorManager({ e, name: YSC_API_AUTH_ERROR })
        }
        try {
          const tokens = await yscApi.auth.refresh({
            refresh_token: refreshAccessTokenCookie.value,
            client_id: key,
          })

          usePersistAPITokens({ tokens })
          config.headers.Authorization = toBearer(tokens.access_token)
          userStore.loggedIn = true
          isRefreshing = false
          processPendingRequests({ token: tokens.access_token })
        }
        catch (e) {
          // rethrow error to stop requests
          if (e instanceof Error) {
            throw $errorManager({ e, name: YSC_API_AUTH_ERROR })
          }
          throw e
        }
      }
      else if (!instance.defaults.headers.common.Authorization) {
        if (accessTokenCookie.value) {
          /*
           * classic client behavior
           */
          config.headers.Authorization = toBearer(accessTokenCookie.value)
        }
      }

      return config
    })

    if (import.meta.server) {
      const createBreadcrumbForResponse = (response: AxiosResponse): Breadcrumb => ({
        type: 'http',
        category: 'ysc-api-js',
        message: `${response.config.method?.toUpperCase()} ${response.config.url} [${response.status}]`,
        data: {
          method: response.config.method?.toUpperCase(),
          url: response.config.url,
          status_code: response.status,
        },
      })
      // Attach an interceptor to add a breadcrumb with request data
      instance.interceptors.response.use((response) => {
        $sentry.addBreadcrumb(createBreadcrumbForResponse(response))

        return response
      }, (error) => {
        if (isAxiosError(error) && error.response) {
          $sentry.addBreadcrumb(createBreadcrumbForResponse(error.response))
        }

        if (error === undefined) {
          error = new YscError({ message: 'Axios error interceptor called with undefined error' })
          error.fingerprint = AXIOS_INTERCEPTOR_UNDEFINED_ERROR
        }
        return Promise.reject(error)
      })
    }

    instance.interceptors.request.use(undefined, (e) => {
      if (e.message === 'Network Error') {
        $errorManager({ e, name: YSC_NETWORK_ERROR })
      }
    })

    // Modern version
    const modernInstance = $fetch.create({
      onRequest({ options }) {
        options.headers = {
          ...options.headers,
          'X-API-KEY': key,
          'Accept-Language': locale.value,
        }
      },
    })

    const apiConsumer = new YscApiConsumer(modernInstance as $Fetch, url)

    return {
      provide: {
        api: yscApi,
        apiConsumer,
        setInstanceAuthorization,
        setRefreshAlreadyFailed,
      },
    }
  },
})
