import type { FieldError } from '~/utils/error/YscApiError'

export const useToastsStore = defineStore('toast', () => {
  interface Toastable {
    timestamp: number
    type: 'success' | 'error'
    fields: FieldError[]
  }
  const toasts = ref<Toastable[]>([])

  const stackToast = (draft: Omit<Toastable, 'timestamp'>) => {
    toasts.value.push({
      timestamp: Date.now(),
      ...draft,
    })
  }

  const stackError = (f: FieldError | FieldError[]) => {
    stackToast({ type: 'error', fields: Array.isArray(f) ? f : [f] })
  }

  const stackSuccess = (f: FieldError | FieldError[]) => {
    stackToast({ type: 'success', fields: Array.isArray(f) ? f : [f] })
  }

  return {
    toasts,
    stackError,
    stackSuccess,
  }
})
