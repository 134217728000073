import { format } from 'date-fns'
import type { Referential } from '@yescapa-dev/ysc-api-js/legacy'
import { DJANGO_MATCH, djangoDateToIso, sanitizeQuery } from './sanitizers'
import type { SanitizeQueryAllowedValues } from './sanitizers'
import type { LocationQuery } from '#vue-router'

export const buildSearchQueryFilters = (filters: SearchFilters, query: LocationQuery, equipments: Referential['maps']['vehicle_equipments']) => {
  const equipmentsTags: string[] = []

  const categories = ['driving_confort', 'on_board', 'outdoor'] as const
  categories.forEach(category => equipmentsTags.push(...equipments[category].map(({ value }) => value)))

  for (const [key, value] of Object.entries(filters)) {
    if (typeof value === 'boolean') {
      if (value) {
        query[key] = 'true'
        // waiting for axios PR to set null value https://github.com/axios/axios/pull/1987
      }
      else if (key === 'smoking' && value === false) {
        query[key] = 'false'
      }
      else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete query[key]
      }
    }
    if (typeof value === 'number' || typeof value === 'string') {
      query[key] = value.toString()
    }
    if (key === 'equipments') {
      // remove existing equipment filters
      for (const tag of equipmentsTags) {
        if (!value.includes(tag)) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete query[tag]
        }
      }
      for (const eq of value) {
        query[eq] = 'true'
      }
    }
    const ALLOWED_KEYS = ['owner_types', 'types', 'date_from', 'date_to', 'marketing_partnership']
    if (ALLOWED_KEYS.includes(key)) {
      if (value) {
        query[key] = value
      }
      else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete query[key]
      }
    }
  }
  return query
}

export const sanitizeSearchQuery = (query: LocationQuery) => {
  const allowedValues: SanitizeQueryAllowedValues = {
    beds: {
      type: 'number',
      min: 2,
      max: 6,
    },
    seatbelts: {
      type: 'number',
      min: 2,
      max: 6,
    },
    min_price: {
      type: 'number',
      min: 0,
    },
    max_price: {
      type: 'number',
      min: 0,
    },
    types: {
      type: 'array',
      allowedArrayValues: ['1', '2', '3', '4', '5', '6', '7'],
    },
    page: {
      type: 'number',
    },
    page_size: {
      type: 'number',
    },
    date_from: {
      type: 'date',
    },
    date_to: {
      type: 'date',
    },
    longitude: {
      type: 'number',
    },
    latitude: {
      type: 'number',
    },
    radius: {
      type: 'number',
    },
  }

  // eslint-disable-next-line prefer-const
  let { mustRedirect, nextQuery } = sanitizeQuery(query, allowedValues)
  // Set beds to same value as seatbelts if it is not set and seatbelts is.
  if (!nextQuery.beds && !!nextQuery.seatbelts) {
    nextQuery.beds = nextQuery.seatbelts
    mustRedirect = true
  }

  return { mustRedirect, nextQuery }
}

export const formatDateForQuery = (d: Date): string => format(d, 'yyyy-MM-dd')

export const fromISOStringWithoutTimeToISOString = (s: string) => {
  const isoMatch = /\d{4}-\d{2}-\d{2}/.exec(s)
  if (!isoMatch || isoMatch[0] !== s) {
    // handle django date alternative format dd-MM-yyyy
    // remove it when /campers/:id is served by nuxt
    const djangoMatch = DJANGO_MATCH.exec(s)
    if (Array.isArray(djangoMatch) && djangoMatch[0] === s) {
      s = djangoDateToIso(djangoMatch[0])
    }
    else {
      return null
    }
  }

  return `${s}T00:00:00`
}
