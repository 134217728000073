import { YscApiAuthError } from '~/utils/error/YscApiAuthError'
import { YscApiBookingGuestError } from '~/utils/error/YscApiBookingGuestError'
import { YscApiBookingOwnerError } from '~/utils/error/YscApiBookingOwnerError'
import { YscApiBrandError } from '~/utils/error/YscApiBrandError'
import { YscApiCamperError } from '~/utils/error/YscApiCamperError'
import { YscApiCamperPriceError } from '~/utils/error/YscApiCamperPriceError'
import { YscApiCertifyPhoneError } from '~/utils/error/YscApiCertifyPhoneError'
import { YscApiChatError } from '~/utils/error/YscApiChatError'
import { YscApiDocumentError } from '~/utils/error/YscApiDocumentError'
import { YscApiEarningsErrors } from '~/utils/error/YscApiEarningsErrors'
import type { ApiErrorResponse, YscApiErrorParams } from '~/utils/error/YscApiError'
import { YscApiError } from '~/utils/error/YscApiError'
import { YscApiInsuranceError } from '~/utils/error/YscApiInsuranceError'
import { YscApiMangopayError } from '~/utils/error/YscApiMangopayError'
import { YscApiManufacturerError } from '~/utils/error/YscApiManufacturerError'
import { YscApiPasswordError } from '~/utils/error/YscApiPasswordError'
import { YscApiReviewError } from '~/utils/error/YscApiReviewError'
import { YscApiSearchError } from '~/utils/error/YscApiSearchError'
import { YscApiUserError } from '~/utils/error/YscApiUserError'
import { YscApiVehicleError } from '~/utils/error/YscApiVehicleError'
import { YscApiWishlistError } from '~/utils/error/YscApiWishlistError'
import { YSC_API_ERROR } from '~/utils/error/YscErrorClasses'
import { YscTrustedShopError } from '~/utils/error/YscTrustedShopError'
import { YscApiPaymentFinalizationError } from '~/utils/error/YscApiPaymentFinalizationError'
import type { RawApiError } from '~/utils/error/isRawApiError'
import { YscApiPaymentSummaryError } from '~/utils/error/YscApiPaymentSummaryError'
import { YscApiPaymentPatchPriceError } from '~/utils/error/YscApiPaymentPatchPriceError'
import { YscApiPlacesError } from '~/utils/error/YscApiPlacesError'
import type { YscFormInstance } from '~/types/commons'

const apiErrorClassMap = {
  YscApiAuthError,
  YscApiError,
  YscApiChatError,
  YscApiUserError,
  YscApiReviewError,
  YscApiInsuranceError,
  YscApiBookingGuestError,
  YscApiBookingOwnerError,
  YscApiCamperError,
  YscApiWishlistError,
  YscApiBrandError,
  YscApiManufacturerError,
  YscApiVehicleError,
  YscApiDocumentError,
  YscApiSearchError,
  YscApiPasswordError,
  YscApiMangopayError,
  YscApiCertifyPhoneError,
  YscApiEarningsErrors,
  YscApiCamperPriceError,
  YscApiPaymentFinalizationError,
  YscApiPaymentSummaryError,
  YscApiPaymentPatchPriceError,
  YscTrustedShopError,
  YscApiPlacesError,
} as const

type YscApiErrorName = keyof typeof apiErrorClassMap

function isApiErrorName(name: string | undefined): name is YscApiErrorName {
  return !!name && Object.keys(apiErrorClassMap).includes(name)
}

export function useHandleApiError() {
  const { $i18n: { locale } } = useNuxtApp()
  const route = useRoute()
  const report = useErrorReport()
  const { stackError } = useToastsStore()
  const handleUnauthenticatedError = useHandleUnauthenticatedError()

  return ({ name, error, formEl }: { name?: string, error: RawApiError, formEl?: Ref<YscFormInstance> }) => {
    if (!error.response) {
      return error
    }
    const { status: apiStatus, data: apiResponseErrorData } = error.response
    const { baseURL: apiURL, data: apiPayloadData, method: apiMethod, url: apiEndpoint, params: apiParamsData } = error.response.config

    // Default to YscApiError class
    let errorClassName: YscApiErrorName = YSC_API_ERROR
    if (isApiErrorName(name)) {
      // but if provided name is a valid api error class then use this one
      errorClassName = name
    }

    const errorArgs: YscApiErrorParams = {
      name: errorClassName,
      locale: toValue(locale),
      route: route?.fullPath,
      apiResponseErrorData: apiResponseErrorData as ApiErrorResponse, // Force cast and trust the api doesn't magically invent a new error response type
      apiStatus,
      apiMethod,
      apiEndpoint,
      apiPayloadData,
      apiParamsData,
      apiURL,
    }

    const yscApiError = new apiErrorClassMap[errorClassName](errorArgs)

    if (formEl?.value) {
      formEl.value.setErrors(yscApiError.fields)
    }
    else {
      stackError(yscApiError.fields)
    }
    report(yscApiError)

    if (yscApiError.apiStatus === 403 || (yscApiError.apiStatus === 400 && yscApiError.apiEndpoint === '/oauth/token/')) {
      const redirected = handleUnauthenticatedError()
      if (redirected) {
        return error
      }
    }

    return yscApiError
  }
}
