import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { CamperQueryValidator } from '~/types/camperQuery'

const { DEPARTURE_AFTERNOON, DEPARTURE_MORNING, ARRIVAL_MORNING, ARRIVAL_AFTERNOON } = Constants.BOOKINGS.HOURS

export const validateQueryHourParameters: CamperQueryValidator = ({ query, availabilities, camper }) => {
  const nextQuery = { ...query }
  let mustRedirect = false

  const validationErrorLogs: string[] = []

  const dateFrom = queryValueToString(query.date_from)
  const dateTo = queryValueToString(query.date_to)
  if (!dateFrom || !dateTo) {
    return { nextQuery, mustRedirect, validationErrorLogs }
  }

  const start = parseDateStringFromQuery(dateFrom)
  const end = parseDateStringFromQuery(dateTo)
  const { forceDepartureMorning, forceDepartureAfternoon, forceArrivalMorning, forceArrivalAfternoon } = validateHoursConstraintsForDateRange(
    { start, end },
    availabilities,
    camper,
  )

  if (!forceArrivalAfternoon && !forceArrivalMorning && !forceDepartureMorning && !forceDepartureAfternoon) {
    return { mustRedirect, nextQuery } // No hour constraint, no need to validate query further
  }

  if (forceArrivalAfternoon && queryValueToNumber(query.hour_to) !== ARRIVAL_AFTERNOON) {
    validationErrorLogs.push(`Dates dictate arrival hour must be ${ARRIVAL_AFTERNOON} - query value is "${query.hour_to}"`)
    nextQuery.hour_to = ARRIVAL_AFTERNOON.toString()
    mustRedirect = true
  }
  else if (forceArrivalMorning && queryValueToNumber(query.hour_to) !== ARRIVAL_MORNING) {
    validationErrorLogs.push(`Dates dictate arrival hour must be ${ARRIVAL_MORNING} - query value is "${query.hour_to}"`)
    nextQuery.hour_to = ARRIVAL_MORNING.toString()
    mustRedirect = true
  }
  if (forceDepartureMorning && queryValueToNumber(query.hour_from) !== DEPARTURE_MORNING) {
    validationErrorLogs.push(`Dates dictate departure hour must be ${DEPARTURE_MORNING} - query value is "${query.hour_from}"`)
    nextQuery.hour_from = DEPARTURE_MORNING.toString()
    mustRedirect = true
  }
  else if (forceDepartureAfternoon && queryValueToNumber(query.hour_from) !== DEPARTURE_AFTERNOON) {
    validationErrorLogs.push(`Dates dictate departure hour must be ${DEPARTURE_AFTERNOON} - query value is "${query.hour_from}"`)
    nextQuery.hour_from = DEPARTURE_AFTERNOON.toString()
    mustRedirect = true
  }

  return { mustRedirect, nextQuery, validationErrorLogs }
}
