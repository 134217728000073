import { OPEN_GRAPH } from '~/constants/pictures'

export const useSeoImageHref = () => {
  return {
    seoImageHref: (x?: string | null) => {
      const url = useTwicpicsURL()
      let safePathname = x ?? OPEN_GRAPH
      if (!safePathname.startsWith('/')) {
        safePathname = `/${safePathname}`
      }
      // By default /library is always set for contextual images
      if (!safePathname.match(new RegExp('^/(rental|library)'))) {
        safePathname = `/rental${safePathname}`
      }
      url.pathname = safePathname
      url.searchParams.append('twic', 'v1/cover=1200x630')
      return decodeURIComponent(url.href)
    },
  }
}
