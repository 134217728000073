// Error export class names, ordered by topic and by alphabetical order
export const YSC_ERROR = 'YscError'

// Third services or navigator's stuff
export const YSC_AXEPTIO_ERROR = 'YscAxeptioError'
export const YSC_CONTENT_ERROR = 'YscContentError'
export const YSC_GEOLOCATION_ERROR = 'YscGeolocationError'
export const YSC_GOOGLE_MAPS_ERROR = 'YscGoogleMapsError'
export const YSC_I18N_ERROR = 'YscI18nError'
export const YSC_NETWORK_ERROR = 'YscNetworkError'
export const YSC_STAFF_ERROR = 'YscStaffError'
export const YSC_TRUSTED_SHOP_ERROR = 'YscTrustedShopError'

// API errors
export const YSC_API_AUTH_ERROR = 'YscApiAuthError'
export const YSC_API_BOOKING_GUEST_ERROR = 'YscApiBookingGuestError'
export const YSC_API_BOOKING_OWNER_ERROR = 'YscApiBookingOwnerError'
export const YSC_API_BRAND_ERROR = 'YscApiBrandError'
export const YSC_API_CAMPER_ERROR = 'YscApiCamperError'
export const YSC_API_CAMPER_PRICE_ERROR = 'YscApiCamperPriceError'
export const YSC_API_CERTIFY_PHONE_ERROR = 'YscApiCertifyPhoneError'
export const YSC_API_CHAT_ERROR = 'YscApiChatError'
export const YSC_API_DOCUMENT_ERROR = 'YscApiDocumentError'
export const YSC_API_EARNINGS_ERROR = 'YscApiEarningsErrors'
export const YSC_API_ERROR = 'YscApiError'
export const YSC_API_INSURANCE_ERROR = 'YscApiInsuranceError'
export const YSC_API_MANGOPAY_ERROR = 'YscApiMangopayError'
export const YSC_API_MANUFACTURER_ERROR = 'YscApiManufacturerError'
export const YSC_API_NEWSLETTER_ERROR = 'YscApiNewsletterError'
export const YSC_API_PASSWORD_ERROR = 'YscApiPasswordError'
export const YSC_API_REVIEW_ERROR = 'YscApiReviewError'
export const YSC_API_SEARCH_ERROR = 'YscApiSearchError'
export const YSC_API_USER_ERROR = 'YscApiUserError'
export const YSC_API_VEHICLE_ERROR = 'YscApiVehicleError'
export const YSC_API_WISHLIST_ERROR = 'YscApiWishlistError'
export const YSC_API_PAYMENT_ERROR = 'YscApiPaymentError'
export const YSC_API_PAYMENT_WEB_REDIRECT_ERROR = 'YscApiPaymentWebRedirectError'
export const YSC_API_CAMPER_SIMULATION_ERROR = 'YscApiCamperSimulationError'
export const YSC_API_LEADS_ERROR = 'YscApiLeadsError'
export const YSC_API_PAYMENT_FINALIZATION_ERROR = 'YscApiPaymentFinalizationError'
export const YSC_API_PAYMENT_SUMMARY_ERROR = 'YscApiPaymentSummaryError'
export const YSC_API_PAYMENT_PATCH_PRICE_ERROR = 'YscApiPaymentPatchPriceError'
export const YSC_API_PLACES_ERROR = 'YscApiPlacesError'
