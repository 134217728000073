import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationNormalized } from '#vue-router'

export const useBookingRequestInsuranceConditions = (route: RouteLocationNormalized) => {
  const { camper, vehicle } = storeToRefs(useCamperStore())
  const { user } = storeToRefs(useUserStore())

  const isDrivingLicenceConditionFulfilled = computed(() => vehicle.value?.driving_licence_required === Constants.VEHICLES.DRIVING_LICENCE.C)
  const hasNoInsurance = computed(() => camper.value?.regular_insurance.slug === Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG)

  const isInsuranceConditionFilled = computed(() => {
    if (!user.value?.borned_on || !camper.value) {
      return false
    }
    return isUserInValidAgeRange({
      birthday: new Date(user.value.borned_on),
      dateFrom: new Date(queryValueToString(route.query.date_from) as string),
      dateTo: new Date(queryValueToString(route.query.date_to) as string),
      insuranceMinDriverAge: camper.value.regular_insurance.condition?.min_drivers_age ?? camper.value.regular_insurance.min_driver_age,
      insuranceMaxDriverAge: camper.value.regular_insurance.condition?.max_drivers_age ?? camper.value.regular_insurance.max_driver_age,
    })
  })

  return {
    hasNoInsurance,
    isDrivingLicenceConditionFulfilled,
    isInsuranceConditionFilled,
  }
}
