/**
 *  Return an objet without entries with null values
 *  Note: does not work properly if object uses Symbol as key (symbol key will be ignored)
 */
export function removeEntresWithNullValues<T extends { [k: string]: unknown }>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj)

      .filter(([_, v]) => v !== null)
      // @ts-expect-error for TS v is of type unknow, but we know it's an object.
      .map(([k, v]) => [k, v === Object(v) ? removeEntresWithNullValues(v) : v]),
  ) as Partial<T>
}
