import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { CONTENT_UNKNOWN } from './errorFingerprints'

export class YscContentError extends YscError {
  constructor(args: YscErrorParams) {
    super(args)

    this.fingerprint = CONTENT_UNKNOWN
  }
}
