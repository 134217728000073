import type { AppDocumentPayload, YscDocumentIban, DocumentPayload, DocumentStatus, MangopayUboDeclaration, YscDocument } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { InputFile } from '~/types/commons'
import type { Refreshable } from '~/types/stores'
import { YSC_API_DOCUMENT_ERROR, YSC_API_MANGOPAY_ERROR } from '~/utils/error/YscErrorClasses'

export const useDocumentsStore = defineStore('documents', () => {
  const current = ref<YscDocument | null>(null)
  const guest = ref<YscDocument[]>([])
  const owner = ref<(YscDocument | YscDocumentIban | MangopayUboDeclaration)[]>([])
  const camper = ref<(YscDocument & { is_mandatory?: boolean })[]>([])

  const fetchDocument = async ({ id_doc, refresh = false }: { id_doc: string } & Refreshable) => {
    if (!refresh && id_doc === current.value?.id) {
      return Promise.resolve()
    }
    const { $api } = useYscApi()

    current.value = await $api.documents.getById(id_doc)
  }

  const fetchDocumentsUserGuest = async () => {
    const { $api } = useYscApi()
    const { user } = useUserStore()
    if (!user?.id) {
      return
    }
    const documents = await $api.documents.getGuestDocumentsByIdUser(user.id)
    const { DRIVING_LICENCE, ID_PROOF, ADDRESS_PROOF } = Constants.DOCUMENTS.TYPE
    guest.value = sortArrayByProps(documents, 'document_type', [DRIVING_LICENCE, ID_PROOF, ADDRESS_PROOF])
  }

  const fetchDocumentsUserOwner = async () => {
    const { $api } = useYscApi()
    const { user } = useUserStore()
    const { $errorManager } = useErrorManager()

    if (!user?.id) {
      return
    }

    const toFetch: Promise<YscDocument[] | YscDocumentIban | MangopayUboDeclaration>[] = [
      $api.documents.getOwnerDocumentsByIdUser(user.id),
      $api.mangopay.getIban(),
    ]

    const { ID_PROOF, IBAN, REGISTRATION_PROOF, REGISTRATION_ASSOCIATION, UBO_DECLARATION, SHAREHOLDER_DECLARATION }
      = Constants.DOCUMENTS.TYPE

    if (user.professional && user.professional_type === Constants.USERS.PROFESIONAL_TYPES.BUSINESS) {
      toFetch.push($api.mangopay.getUBODeclaration())
    }

    const [ownerDocumentsRequest, ibanRequest, uboRequest] = await Promise.allSettled(toFetch)
    const documents: (YscDocument | YscDocumentIban | MangopayUboDeclaration)[] = []

    const pushDocuments = (doc: YscDocument[] | YscDocumentIban | MangopayUboDeclaration) => {
      if (Array.isArray(doc)) {
        documents.push(...doc)
      }
      else {
        documents.push(doc)
      }
    }
    if (ownerDocumentsRequest.status === 'rejected') {
      $errorManager({ e: ownerDocumentsRequest.reason, name: YSC_API_DOCUMENT_ERROR })
    }
    else {
      pushDocuments(ownerDocumentsRequest.value)
    }

    if (ibanRequest.status === 'rejected') {
      $errorManager({ e: ibanRequest.reason, name: YSC_API_MANGOPAY_ERROR })
    }
    else {
      pushDocuments(ibanRequest.value)
    }

    if (uboRequest?.status === 'rejected') {
      $errorManager({ e: uboRequest.reason, name: YSC_API_MANGOPAY_ERROR })
    }
    else if (uboRequest) {
      pushDocuments(uboRequest.value)
    }

    owner.value = sortArrayByProps(documents, 'document_type', [
      ID_PROOF,
      IBAN,
      REGISTRATION_PROOF,
      REGISTRATION_ASSOCIATION,
      UBO_DECLARATION,
      SHAREHOLDER_DECLARATION,
    ])

    return owner.value
  }

  const updateDocument = async (form: AppDocumentPayload) => {
    if (!current.value?.id) {
      return
    }
    const { $api } = useYscApi()
    current.value = await $api.documents.update(current.value.id, form)
  }

  const uploadPages = async ({ pages }: { pages: InputFile[] }) => {
    if (current.value === null) {
      return
    }

    const { $api } = useYscApi()
    const id = current.value.id
    const postPages = await Promise.all(
      pages.filter(({ file }) => !!file).map((inputFile) => {
        const formData = new FormData()
        formData.append('page', inputFile.file as File)
        return $api.documents.addDocumentPage(id, formData)
      }),
    )

    const isFirstUpload = (postPages[0] ?? current.value.pages[0]).doc_status !== Constants.DOCUMENTS.STATUS.VALIDATION_ASKED
    if (isFirstUpload) {
      const payload: DocumentPayload & { status: DocumentStatus } = { status: Constants.DOCUMENTS.STATUS.ADDED }
      // bug if patching only status on expiration_date_mandatory document (ie: MOT)
      // https://jlmcc.slack.com/archives/C01GT4VNY8L/p1706626633691109
      if (current.value.expiration_date_mandatory) {
        payload.expiration_date = current.value.expiration_date
      }
      current.value = await $api.documents.update(current.value.id, payload)
    }
    current.value = await $api.documents.getById(current.value.id)
  }

  const deletePage = async (idPage: string) => {
    if (current.value === null) {
      return
    }

    const { $api } = useYscApi()
    await $api.documents.removeDocumentPage(current.value.id, idPage)
    current.value = await $api.documents.getById(current.value.id)
  }

  const fetchVehicleDocuments = async ({ id }: { id: number }) => {
    const { $api } = useYscApi()
    const documents = await $api.documents.getByIdVehicle(id)
    const { CAR_REGISTRATION, CAR_PROOF_INSURANCE } = Constants.DOCUMENTS.TYPE
    const { camperDocuments } = useCamperStore()
    const documentsWithStatus: YscDocument[] = documents.map(d => ({
      ...d,
      is_mandatory: camperDocuments.find(({ document_type }) => document_type === d.document_type)?.is_mandatory ?? false,
    }))
    camper.value = sortArrayByProps(documentsWithStatus, 'document_type', [CAR_REGISTRATION, CAR_PROOF_INSURANCE])
  }

  return {
    current,
    guest,
    owner,
    camper,
    fetchDocument,
    fetchDocumentsUserGuest,
    fetchDocumentsUserOwner,
    updateDocument,
    uploadPages,
    deletePage,
    fetchVehicleDocuments,
  }
})
