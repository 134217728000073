export const useSeoText = ({ title, description }: { title?: MaybeRefOrGetter<string>, description?: MaybeRefOrGetter<string> } = {}) => {
  const { $i18n: { t } } = useNuxtApp()
  const seoDescription = toValue(description) ?? t('commons.meta.description')
  const titleValue = toValue(title)
  const seoTitle = titleValue ? titleValue : t('commons.meta.title') // keep ternary as titleValue can be en empty string
  useHead({
    title: () => seoTitle,
    meta: [
      getHeadMetaName(({ name: 'description', content: seoDescription })),
      getHeadMetaName(({ name: 'twitter:title', content: seoTitle })),
      getMetaProperty({ property: 'og:title', content: seoTitle }),
      getHeadMetaName(({ name: 'description', content: seoDescription })),
      getHeadMetaName(({ name: 'twitter:description', content: seoDescription })),
      getMetaProperty({ property: 'og:description', content: seoDescription }),
    ],
  })
}
