import { YscApiError } from './YscApiError'
import type { YscApiErrorParams } from './YscApiError'
import { PAYMENT_PATCH_PRICES } from './errorFingerprints'

export class YscApiPaymentPatchPriceError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = PAYMENT_PATCH_PRICES
  }
}
