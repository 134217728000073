import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { MoneyBoxItemResponse, PatchUserMePayload, PasswordPayload, AuthTokenResponse, LoginPayload, UserMeResponse, LogAsPayload } from '@yescapa-dev/ysc-api-js/legacy'
import axios from 'axios'
import { AUTH_LOGIN_EVENT, AUTH_LOGIN_METHOD_PARAM } from '~/utils/analytics/auth'

export const useUserStore = defineStore('user', () => {
  const loggedIn = ref(false)

  const { event } = useGtag()
  const user = ref<UserMeResponse | null>(null)

  const isLoggedIn = computed(() => user.value !== null)
  const isStaff = computed(() => user.value?.is_staff ?? false)
  const isOwner = computed(() => user.value?.is_owner ?? false)

  const moneyboxes = ref<MoneyBoxItemResponse[]>([])

  const driverIdentityChecked = ref(false)
  const isEmergencyWarningSeen = ref(false)

  const {
    public: {
      api: {
        rental: { key },
      },
    },
  } = useRuntimeConfig()

  const manageToken = async (tokens: AuthTokenResponse) => {
    const { $setInstanceAuthorization } = useNuxtApp()

    usePersistAPITokens({ tokens })
    await $setInstanceAuthorization({ token: tokens.access_token })
    loggedIn.value = true
  }

  const removeAuthentication = () => {
    const { refreshAccessTokenCookie, accessTokenCookie } = useAuthCookies()
    refreshAccessTokenCookie.value = null
    accessTokenCookie.value = null
  }

  const logIn = async (payload: MaybeRefOrGetter<Omit<LoginPayload, 'client_id'>>) => {
    const { $api } = useYscApi()
    event(AUTH_LOGIN_EVENT, { [AUTH_LOGIN_METHOD_PARAM]: 'email' })
    const tokens = await $api.auth.login({
      ...toValue(payload),
      client_id: key,
    })
    await manageToken(tokens)
    await fetchUser()
  }

  const logAs = async (payload: LogAsPayload) => {
    const { $api } = useYscApi()
    const tokens = await $api.logAs.byUserId(payload)
    await manageToken(tokens)
  }

  const logOut = async () => {
    const { $api } = useYscApi()
    const { accessTokenCookie } = useAuthCookies()
    const token = accessTokenCookie.value
    if (!token) {
      // No access token, we're already logged out
      return
    }
    await axios.request({
      method: 'GET',
      url: '/api/internals/logout',
    })
    await $api.auth.logout({ token, client_id: key })
    removeAuthentication()
    reloadNuxtApp({ path: '/' })
  }

  const fetchUser = async ({ refresh = false } = {}) => {
    const { setSentryUser } = useSetSentryUser()
    const { $api } = useYscApi()
    if (!refresh && user.value !== null) {
      return
    }

    const me = await $api.users.getMe()
    user.value = me
    setSentryUser()
  }

  const updateUser = async (payload: PatchUserMePayload) => {
    const { $api } = useYscApi()
    user.value = await $api.users.updateMe(payload)
  }

  const updatePassword = async (payload: PasswordPayload) => {
    const { $api } = useYscApi()
    await $api.password.post(payload)
  }

  const fetchMoneyboxes = async () => {
    const { $api } = useYscApi()
    moneyboxes.value = await $api.users.getMoneyboxes()
  }

  const areMandatoryGuestDocumentsValidated = computed(() => {
    if (!isLoggedIn.value || !user.value?.documents) {
      return false
    }
    return (
      useDocumentsMergedStatus({
        documents: user.value.documents.filter(({ is_mandatory }) => is_mandatory),
      }) === Constants.DOCUMENTS.STATUS.VALIDATED
    )
  })

  const areMandatoryGuestDocumentsInValidation = computed(() => {
    if (!isLoggedIn.value || !user.value?.documents) {
      return false
    }
    return (
      useDocumentsMergedStatus({
        documents: user.value.documents.filter(({ is_mandatory }) => is_mandatory),
      }) === Constants.DOCUMENTS.STATUS.VALIDATION_ASKED
    )
  })

  return {
    loggedIn,
    user,
    isLoggedIn,
    isStaff,
    isOwner,
    moneyboxes,
    driverIdentityChecked,
    isEmergencyWarningSeen,
    logIn,
    logAs,
    logOut,
    fetchUser,
    updateUser,
    updatePassword,
    removeAuthentication,
    fetchMoneyboxes,
    areMandatoryGuestDocumentsValidated,
    areMandatoryGuestDocumentsInValidation,
  }
})
