
// @ts-nocheck


export const localeCodes =  [
  "ca",
  "de",
  "de-at",
  "de-ch",
  "en-us",
  "en-gb",
  "en-ie",
  "es",
  "fr",
  "fr-be",
  "fr-ch",
  "it",
  "it-ch",
  "nl",
  "nl-be",
  "pt-pt"
]

export const localeLoaders = {
  "ca": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/ca/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/ca/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_ca_main_json" */), cache: true },
{ key: "../i18n/locales/ca/main.json", load: () => import("../i18n/locales/ca/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_ca_main_json" */), cache: true }],
  "de": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_de_main_json" */), cache: true },
{ key: "../i18n/locales/de/main.json", load: () => import("../i18n/locales/de/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_de_main_json" */), cache: true }],
  "de-at": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-AT/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-AT/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_de_AT_main_json" */), cache: true },
{ key: "../i18n/locales/de-AT/main.json", load: () => import("../i18n/locales/de-AT/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_de_AT_main_json" */), cache: true }],
  "de-ch": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-CH/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-CH/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_de_CH_main_json" */), cache: true },
{ key: "../i18n/locales/de-CH/main.json", load: () => import("../i18n/locales/de-CH/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_de_CH_main_json" */), cache: true }],
  "en-us": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-US/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-US/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_en_US_main_json" */), cache: true },
{ key: "../i18n/locales/en-US/main.json", load: () => import("../i18n/locales/en-US/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_en_US_main_json" */), cache: true }],
  "en-gb": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_en_main_json" */), cache: true },
{ key: "../i18n/locales/en/main.json", load: () => import("../i18n/locales/en/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_en_main_json" */), cache: true }],
  "en-ie": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-IE/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-IE/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_en_IE_main_json" */), cache: true },
{ key: "../i18n/locales/en-IE/main.json", load: () => import("../i18n/locales/en-IE/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_en_IE_main_json" */), cache: true }],
  "es": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/es/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/es/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_es_main_json" */), cache: true },
{ key: "../i18n/locales/es/main.json", load: () => import("../i18n/locales/es/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_es_main_json" */), cache: true }],
  "fr": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_fr_main_json" */), cache: true },
{ key: "../i18n/locales/fr/main.json", load: () => import("../i18n/locales/fr/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_fr_main_json" */), cache: true }],
  "fr-be": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-BE/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-BE/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_fr_BE_main_json" */), cache: true },
{ key: "../i18n/locales/fr-BE/main.json", load: () => import("../i18n/locales/fr-BE/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_fr_BE_main_json" */), cache: true }],
  "fr-ch": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-CH/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-CH/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_fr_CH_main_json" */), cache: true },
{ key: "../i18n/locales/fr-CH/main.json", load: () => import("../i18n/locales/fr-CH/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_fr_CH_main_json" */), cache: true }],
  "it": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_it_main_json" */), cache: true },
{ key: "../i18n/locales/it/main.json", load: () => import("../i18n/locales/it/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_it_main_json" */), cache: true }],
  "it-ch": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it-CH/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it-CH/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_it_CH_main_json" */), cache: true },
{ key: "../i18n/locales/it-CH/main.json", load: () => import("../i18n/locales/it-CH/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_it_CH_main_json" */), cache: true }],
  "nl": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_nl_main_json" */), cache: true },
{ key: "../i18n/locales/nl/main.json", load: () => import("../i18n/locales/nl/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_nl_main_json" */), cache: true }],
  "nl-be": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl-BE/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl-BE/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_nl_BE_main_json" */), cache: true },
{ key: "../i18n/locales/nl-BE/main.json", load: () => import("../i18n/locales/nl-BE/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_nl_BE_main_json" */), cache: true }],
  "pt-pt": [{ key: "../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/pt/main.json", load: () => import("../node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/pt/main.json" /* webpackChunkName: "locale__home_node_app_node_modules__64yescapa_dev_ysc_components_dist_runtime_i18n_locales_pt_main_json" */), cache: true },
{ key: "../i18n/locales/pt/main.json", load: () => import("../i18n/locales/pt/main.json" /* webpackChunkName: "locale__home_node_app_i18n_locales_pt_main_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "ca",
      "iso": "ca",
      "yscCode": "ca",
      "forceLocaleCode": "ca",
      "domain": "www.yescapa.cat",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/ca/main.json",
        "i18n/locales/ca/main.json"
      ]
    },
    {
      "code": "de",
      "iso": "de",
      "yscCode": "de",
      "forceLocaleCode": "de",
      "domain": "www.yescapa.de",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de/main.json",
        "i18n/locales/de/main.json"
      ]
    },
    {
      "code": "de-at",
      "iso": "de-AT",
      "yscCode": "de-at",
      "forceLocaleCode": "de-at",
      "domain": "www.yescapa.at",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-AT/main.json",
        "i18n/locales/de-AT/main.json"
      ]
    },
    {
      "code": "de-ch",
      "iso": "de-CH",
      "yscCode": "de-ch",
      "forceLocaleCode": "de-ch",
      "domain": "www.yescapa.ch",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-CH/main.json",
        "i18n/locales/de-CH/main.json"
      ]
    },
    {
      "code": "en-us",
      "iso": "en-GB",
      "yscCode": "en",
      "forceLocaleCode": "en-gb",
      "domain": "www.yescapa.com",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-US/main.json",
        "i18n/locales/en-US/main.json"
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-GB",
      "yscCode": "en-gb",
      "forceLocaleCode": "en-gb",
      "domain": "www.yescapa.co.uk",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en/main.json",
        "i18n/locales/en/main.json"
      ]
    },
    {
      "code": "en-ie",
      "iso": "en-IE",
      "yscCode": "en-ie",
      "forceLocaleCode": "en-ie",
      "domain": "www.yescapa.ie",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-IE/main.json",
        "i18n/locales/en-IE/main.json"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "yscCode": "es",
      "forceLocaleCode": "es",
      "domain": "www.yescapa.es",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/es/main.json",
        "i18n/locales/es/main.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr",
      "yscCode": "fr",
      "forceLocaleCode": "fr",
      "domain": "www.yescapa.fr",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr/main.json",
        "i18n/locales/fr/main.json"
      ]
    },
    {
      "code": "fr-be",
      "iso": "fr-BE",
      "yscCode": "fr-be",
      "forceLocaleCode": "fr-be",
      "domain": "www.yescapa.be",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-BE/main.json",
        "i18n/locales/fr-BE/main.json"
      ]
    },
    {
      "code": "fr-ch",
      "iso": "fr-CH",
      "yscCode": "fr-ch",
      "forceLocaleCode": "fr-ch",
      "domain": "fr.yescapa.ch",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-CH/main.json",
        "i18n/locales/fr-CH/main.json"
      ]
    },
    {
      "code": "it",
      "iso": "it",
      "yscCode": "it",
      "forceLocaleCode": "it",
      "domain": "www.yescapa.it",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it/main.json",
        "i18n/locales/it/main.json"
      ]
    },
    {
      "code": "it-ch",
      "iso": "it-CH",
      "yscCode": "it-ch",
      "forceLocaleCode": "it-ch",
      "domain": "it.yescapa.ch",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it-CH/main.json",
        "i18n/locales/it-CH/main.json"
      ]
    },
    {
      "code": "nl",
      "iso": "nl",
      "yscCode": "nl",
      "forceLocaleCode": "nl",
      "domain": "www.yescapa.nl",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl/main.json",
        "i18n/locales/nl/main.json"
      ]
    },
    {
      "code": "nl-be",
      "iso": "nl-BE",
      "yscCode": "nl-be",
      "forceLocaleCode": "nl-be",
      "domain": "nl.yescapa.be",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl-BE/main.json",
        "i18n/locales/nl-BE/main.json"
      ]
    },
    {
      "code": "pt-pt",
      "iso": "pt",
      "yscCode": "pt-pt",
      "forceLocaleCode": "pt-pt",
      "domain": "www.yescapa.pt",
      "files": [
        "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/pt/main.json",
        "i18n/locales/pt/main.json"
      ]
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "i18n/locales",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": [
    {
      "langDir": "/home/node/app/node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales",
      "locales": [
        {
          "code": "ca",
          "iso": "ca",
          "file": "ca/main.json"
        },
        {
          "code": "de",
          "iso": "de",
          "file": "de/main.json"
        },
        {
          "code": "de-at",
          "iso": "de-AT",
          "file": "de-AT/main.json"
        },
        {
          "code": "de-ch",
          "iso": "de-CH",
          "file": "de-CH/main.json"
        },
        {
          "code": "en-gb",
          "iso": "en-GB",
          "file": "en/main.json"
        },
        {
          "code": "en-ie",
          "iso": "en-IE",
          "file": "en-IE/main.json"
        },
        {
          "code": "en-us",
          "iso": "en-US",
          "file": "en-US/main.json"
        },
        {
          "code": "es",
          "iso": "es",
          "file": "es/main.json"
        },
        {
          "code": "fr",
          "iso": "fr",
          "file": "fr/main.json"
        },
        {
          "code": "fr-be",
          "iso": "fr-BE",
          "file": "fr-BE/main.json"
        },
        {
          "code": "fr-ch",
          "iso": "fr-CH",
          "file": "fr-CH/main.json"
        },
        {
          "code": "it",
          "iso": "it",
          "file": "it/main.json"
        },
        {
          "code": "it-ch",
          "iso": "it-CH",
          "file": "it-CH/main.json"
        },
        {
          "code": "nl",
          "iso": "nl",
          "file": "nl/main.json"
        },
        {
          "code": "nl-be",
          "iso": "nl-BE",
          "file": "nl-BE/main.json"
        },
        {
          "code": "pt-pt",
          "iso": "pt",
          "file": "pt/main.json"
        }
      ]
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "ca",
    "iso": "ca",
    "yscCode": "ca",
    "forceLocaleCode": "ca",
    "domain": "www.yescapa.cat",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/ca/main.json"
      },
      {
        "path": "i18n/locales/ca/main.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de",
    "yscCode": "de",
    "forceLocaleCode": "de",
    "domain": "www.yescapa.de",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de/main.json"
      },
      {
        "path": "i18n/locales/de/main.json"
      }
    ]
  },
  {
    "code": "de-at",
    "iso": "de-AT",
    "yscCode": "de-at",
    "forceLocaleCode": "de-at",
    "domain": "www.yescapa.at",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-AT/main.json"
      },
      {
        "path": "i18n/locales/de-AT/main.json"
      }
    ]
  },
  {
    "code": "de-ch",
    "iso": "de-CH",
    "yscCode": "de-ch",
    "forceLocaleCode": "de-ch",
    "domain": "www.yescapa.ch",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/de-CH/main.json"
      },
      {
        "path": "i18n/locales/de-CH/main.json"
      }
    ]
  },
  {
    "code": "en-us",
    "iso": "en-GB",
    "yscCode": "en",
    "forceLocaleCode": "en-gb",
    "domain": "www.yescapa.com",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-US/main.json"
      },
      {
        "path": "i18n/locales/en-US/main.json"
      }
    ]
  },
  {
    "code": "en-gb",
    "iso": "en-GB",
    "yscCode": "en-gb",
    "forceLocaleCode": "en-gb",
    "domain": "www.yescapa.co.uk",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en/main.json"
      },
      {
        "path": "i18n/locales/en/main.json"
      }
    ]
  },
  {
    "code": "en-ie",
    "iso": "en-IE",
    "yscCode": "en-ie",
    "forceLocaleCode": "en-ie",
    "domain": "www.yescapa.ie",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/en-IE/main.json"
      },
      {
        "path": "i18n/locales/en-IE/main.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es",
    "yscCode": "es",
    "forceLocaleCode": "es",
    "domain": "www.yescapa.es",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/es/main.json"
      },
      {
        "path": "i18n/locales/es/main.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr",
    "yscCode": "fr",
    "forceLocaleCode": "fr",
    "domain": "www.yescapa.fr",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr/main.json"
      },
      {
        "path": "i18n/locales/fr/main.json"
      }
    ]
  },
  {
    "code": "fr-be",
    "iso": "fr-BE",
    "yscCode": "fr-be",
    "forceLocaleCode": "fr-be",
    "domain": "www.yescapa.be",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-BE/main.json"
      },
      {
        "path": "i18n/locales/fr-BE/main.json"
      }
    ]
  },
  {
    "code": "fr-ch",
    "iso": "fr-CH",
    "yscCode": "fr-ch",
    "forceLocaleCode": "fr-ch",
    "domain": "fr.yescapa.ch",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/fr-CH/main.json"
      },
      {
        "path": "i18n/locales/fr-CH/main.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it",
    "yscCode": "it",
    "forceLocaleCode": "it",
    "domain": "www.yescapa.it",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it/main.json"
      },
      {
        "path": "i18n/locales/it/main.json"
      }
    ]
  },
  {
    "code": "it-ch",
    "iso": "it-CH",
    "yscCode": "it-ch",
    "forceLocaleCode": "it-ch",
    "domain": "it.yescapa.ch",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/it-CH/main.json"
      },
      {
        "path": "i18n/locales/it-CH/main.json"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl",
    "yscCode": "nl",
    "forceLocaleCode": "nl",
    "domain": "www.yescapa.nl",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl/main.json"
      },
      {
        "path": "i18n/locales/nl/main.json"
      }
    ]
  },
  {
    "code": "nl-be",
    "iso": "nl-BE",
    "yscCode": "nl-be",
    "forceLocaleCode": "nl-be",
    "domain": "nl.yescapa.be",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/nl-BE/main.json"
      },
      {
        "path": "i18n/locales/nl-BE/main.json"
      }
    ]
  },
  {
    "code": "pt-pt",
    "iso": "pt",
    "yscCode": "pt-pt",
    "forceLocaleCode": "pt-pt",
    "domain": "www.yescapa.pt",
    "files": [
      {
        "path": "node_modules/@yescapa-dev/ysc-components/dist/runtime/i18n/locales/pt/main.json"
      },
      {
        "path": "i18n/locales/pt/main.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
