import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { BOOKING_OWNER_UNKNOWN } from './errorFingerprints'

export class YscApiBookingOwnerError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = BOOKING_OWNER_UNKNOWN
  }
}
