import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { AvailabilitiesListItemResponse, Camper } from '@yescapa-dev/ysc-api-js/legacy'
import { addMonths, areIntervalsOverlapping, startOfDay } from 'date-fns'
import type { CamperQueryValidator } from '~/types/camperQuery'
import type { LocationQuery } from '#vue-router'

const { DEPARTURE_AFTERNOON, ARRIVAL_MORNING } = Constants.BOOKINGS.HOURS

export const validateQueryDateRange = (({ query, availabilities, camper: { max_months_future_rental } }: { query: LocationQuery, availabilities: AvailabilitiesListItemResponse[], camper: Pick<Camper, 'max_months_future_rental'> }) => {
  const dateFrom = queryValueToString(query.date_from)
  const dateTo = queryValueToString(query.date_to)
  if (!dateFrom || !dateTo) {
    return { mustRedirect: true, nextQuery: removeDatesAndHoursFromCamperQuery(query) }
  }
  const interval = {
    start: parseDateStringFromQuery(dateFrom),
    end: parseDateStringFromQuery(dateTo),
  }

  const camperMaxReturnDate = startOfDay(addMonths(new Date(), max_months_future_rental))

  if (interval.end > camperMaxReturnDate) {
    return { mustRedirect: true, nextQuery: removeDatesAndHoursFromCamperQuery(query) }
  }

  const unavailableDates = availabilities
    .filter(({ availability }) => availability === Constants.VEHICLES.AVAILABILITY.NOT_AVAILABLE)
    .map(({ date_from, hour_from, date_to, hour_to }) => {
      const start = setDateAsStartDate(new Date(date_from))
      const end = setDateAsEndDate(new Date(date_to))

      // Half day cases, free the previous / next day.
      if (hour_from === DEPARTURE_AFTERNOON) {
        start.setDate(start.getDate() + 1)
      }
      if (hour_to === ARRIVAL_MORNING) {
        end.setDate(end.getDate() - 1)
      }
      return { start, end }
    })

  const isValid = !unavailableDates.some(unavailablesInterval =>
    areIntervalsOverlapping(interval, unavailablesInterval, { inclusive: true }),
  )

  let nextQuery = query

  if (!isValid) {
    nextQuery = removeDatesAndHoursFromCamperQuery(query)
  }

  return { mustRedirect: !isValid, nextQuery, validationErrorLogs: isValid ? [] : ['Selected dates overlap unavailable period'] }
}) satisfies CamperQueryValidator
