import type { BookingMetaState, BookingOwnerDetails, BookingOwnerListItem, BookingOwnerListQueryParameters, ListResponse, Review } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { DEFAULT_BOOKINGS_PAGE_SIZE } from '~/constants/bookings'
import type { BookingsPrices } from '~/types/bookingRequest'
import type { WithRequiredProperty } from '~/types/commons'

export const useBookingsOwnerStore = defineStore('bookingsOwner', () => {
  const { $api } = useYscApi()

  const {
    TO_DO,
    WAITING,
    CONFIRMED,
    ARCHIVED,
  } = Constants.BOOKINGS.LISTING_STATE

  const all = ref<Record<BookingMetaState, ListResponse<BookingOwnerListItem>>>({
    [TO_DO]: { count: 0, results: [] },
    [WAITING]: { count: 0, results: [] },
    [CONFIRMED]: { count: 0, results: [] },
    [ARCHIVED]: { count: 0, results: [] },
  })

  const header = ref<{
    id?: string | number | null
    meta_state?: string | null
    date_time_from?: string | null
    date_time_to?: string | null
  }>({
    id: null,
    meta_state: null,
    date_time_from: null,
    date_time_to: null,
  })

  const bookingOwner = ref<BookingOwnerDetails | null>(null)
  const prices = ref<BookingsPrices | null>(null)
  const guestReviews = ref<ListResponse<Review> | null>(null)

  const fetchBookingsOwnerByState = async ({
    meta_state,
    page = 1,
    page_size = DEFAULT_BOOKINGS_PAGE_SIZE,
    year = undefined,
    state = undefined,
  }: WithRequiredProperty<BookingOwnerListQueryParameters, 'meta_state'>) => {
    const data = await $api.bookings.getAll({
      meta_state,
      page,
      page_size,
      year,
      state,
    })

    all.value[meta_state] = data
  }

  const setBookingOwnerHeader = ({ id, meta_state, date_time_from, date_time_to }: { id?: number | string, meta_state?: string, date_time_from?: string, date_time_to?: string }) => {
    header.value = { id, meta_state, date_time_from, date_time_to }
  }

  const fetchBookingOwner = async ({ id, refresh }: { id?: number, refresh?: boolean }) => {
    id = id || bookingOwner.value?.id
    if (!id || (!refresh && id === bookingOwner.value?.id)) {
      return Promise.resolve()
    }
    const data = await $api.bookings.get(id)
    bookingOwner.value = data
    setBookingOwnerHeader(data)
  }

  const fetchBookingOwnerPrices = async ({ id, refresh = false }: { id: number, refresh?: boolean }) => {
    if (!refresh && +id === prices.value?.booking_pk) {
      return Promise.resolve()
    }

    const data = await $api.bookings.getPrices(id)
    prices.value = {
      ...data,
      booking_pk: id,
    }
  }

  const fetchGuestReviews = async () => {
    if (!bookingOwner.value?.guest?.id) {
      // safeguard
      throw new Error('Can not fetch guest reviews with no current booking')
    }

    guestReviews.value = await $api.reviews.getAll({ target_id: bookingOwner.value.guest.id, author_type: 'owner' })
  }

  return {
    all,
    prices,
    header,
    bookingOwner,
    guestReviews,
    fetchBookingsOwnerByState,
    fetchBookingOwner,
    fetchBookingOwnerPrices,
    fetchGuestReviews,
    setBookingOwnerHeader,
  }
})
