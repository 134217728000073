import { YscError } from '~/utils/error/YscError'
import { YSC_API_NEWSLETTER_ERROR, YSC_I18N_ERROR } from '~/utils/error/YscErrorClasses'
import { isRawApiError } from '~/utils/error/isRawApiError'
import { getMiscellaneousErrorClass } from '~/utils/error/getMiscellaneousErrorClass'
import type { YscFormInstance } from '~/types/commons'

export default defineNuxtPlugin({
  name: 'ysc:error-manager',
  dependsOn: ['ysc:sentry', 'i18n:plugin'],
  parallel: true,
  setup() {
    const {
      $i18n: { locale },
      runWithContext,
    } = useNuxtApp()

    const route = useRoute()
    const report = useErrorReport()

    const handleApiError = useHandleApiError()
    const handleI18nError = useHandleI18nError()
    const handleNewsletterApiError = useHandleNewsletterApiError()

    function errorManager<T extends Error & { [k: string]: any }>({ e, name, formEl }: { e: T, name?: string, formEl?: Ref<YscFormInstance> }) {
      return runWithContext(() => {
        let error: Error = e
        const errorClassName = name
        const errorArgs = {
          locale: toValue(locale),
          route: route?.fullPath,
          name: errorClassName,
        }

        // error was already handled (ie: in 20.fetchMandatoryData.server for YscTrustedShopError)
        if (error instanceof YscError) {
          return error
        }

        if (isRawApiError(error)) {
          return handleApiError({ name, error, formEl })
        }

        if (errorClassName === YSC_I18N_ERROR) {
          return handleI18nError(e)
        }

        // Add newsletter error data
        if (errorClassName === YSC_API_NEWSLETTER_ERROR) {
          return handleNewsletterApiError({ e })
        }

        // Override Error if it's an Ysc one, report as is otherwise (TypeError, ReferenceError, ...)
        if (error.name === 'Error' && errorClassName?.startsWith('Ysc')) {
          const ErrorClass = getMiscellaneousErrorClass(errorClassName)
          error = new ErrorClass(errorArgs)
        }

        report(error)
        // In case you need to throw it again it will be typed
        return error
      })
    }

    return {
      provide: {
        errorManager,
      },
    }
  },
})
