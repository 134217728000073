<script setup lang="ts">
interface Props {
  container?: 'container' | 'container-dashboard' | 'container-search'
}
const props = withDefaults(defineProps<Props>(), {
  container: 'container',
})
</script>

<template>
  <header class="relative bg-white">
    <div
      class="grid content-center items-center gap-x-4 gap-y-2 md:gap-x-8"
      :class="[props.container, $slots.center ? 'grid-cols-[auto_1fr_auto]' : 'grid-cols-[auto_1fr]']"
    >
      <AppLogo class="col-start-1 row-start-1" />

      <div
        v-if="$slots.center"
        class="col-span-full row-start-2 md:col-span-1 md:col-start-2 md:row-start-1 md:max-w-2xl"
      >
        <slot name="center" />
      </div>

      <div
        v-if="$slots.end"
        :class="[$slots.center ? 'col-start-3' : 'col-start-2']"
        class="row-start-1"
      >
        <slot name="end" />
      </div>
    </div>
  </header>
</template>
