import type { Routing } from '@yescapa-dev/ysc-api-js/legacy'

export const useRoutingStore = defineStore('routing', () => {
  const routing = ref<Routing | null>(null)

  const fetchRouting = async () => {
    if (routing.value) {
      return
    }

    const { localeProperties: { yscCode } } = useLocaleProperties()
    routing.value = await $fetch('/api/internals/referential/routing', {
      query: {
        lang: yscCode,
      },
    })
  }

  return {
    routing,
    fetchRouting,
  }
})
