import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { REVIEW_UNKNOWN } from './errorFingerprints'

export class YscApiReviewError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = REVIEW_UNKNOWN
  }
}
