import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { WISHLIST_UNKNOWN } from './errorFingerprints'

export class YscApiWishlistError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = WISHLIST_UNKNOWN
  }
}
