// FIXME api-js
type PlaceType = 'COUNTRY' | 'LVL1' | 'LVL2' | 'LOC' | 'SUB' | 'POI'
type PlaceSlug = { slug: string, place_type: PlaceType, name: string }
type Place = {
  name: string
  place_type: PlaceType
  description_html: string
  latitude: number
  longitude: number
  radius: number
  neighbors: PlaceSlug[]
  ancestors: PlaceSlug[]
  alternate_slugs: { language: string, slug: string }[]
  picture_id: string
}

export const useCampervanHire = defineStore('campervanHire', () => {
  const { $api } = useYscApi()
  const pageData = ref<Place | undefined>()

  const fetchPageData = async (slug: string) => {
    const data = await $api.instance.$request<Place>({
      url: `/v4/geolocation/places/${slug}/`,
      method: 'get',
    })
    const { ancestors = [], ...rest } = data

    pageData.value = {
      ...rest,
      ancestors: sortArrayByProps(ancestors, 'place_type', ['COUNTRY', 'LVL1', 'LVL2', 'LOC', 'SUB', 'POI']),
    }
  }

  return { pageData, fetchPageData }
})
