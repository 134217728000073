import type { ApiErrorResponse, OauthErrorResponse, YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { AUTH_BAD_CREDENTIALS, AUTH_INVALID_GRANT, AUTH_MISSING_CREDENTIALS, AUTH_UNKNOWN } from './errorFingerprints'

export class YscApiAuthError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    const { apiResponseErrorData } = args

    const isOauthErrorResponse = (data: ApiErrorResponse): data is OauthErrorResponse => {
      return !!data && typeof data === 'object' && 'error' in data && 'error_description' in data
    }

    if (isOauthErrorResponse(apiResponseErrorData)) {
      const { error, error_description = '' } = apiResponseErrorData

      // typically a bad refresh token but maybe something else?
      if (error.match('invalid_grant')) {
        this.fingerprint = AUTH_INVALID_GRANT
        return
      }

      // when the username and the password don't match
      if (error_description.match('Invalid credentials')) {
        this.fingerprint = AUTH_BAD_CREDENTIALS
        return
      }

      // when username or password is missing
      if (error_description.match('missing')) {
        this.fingerprint = AUTH_MISSING_CREDENTIALS
      }
    }
    else {
      this.fingerprint = AUTH_UNKNOWN
    }
  }
}
