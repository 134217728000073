import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { BRAND_UNKNOWN } from './errorFingerprints'

export class YscApiBrandError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = BRAND_UNKNOWN
  }
}
