import { isString } from '@yescapa-dev/ysc-api-js/modern'
import type { LocationQueryValue } from '#vue-router'

export const queryValueToArray = (value: LocationQueryValue | LocationQueryValue[]) => {
  if (Array.isArray(value)) {
    return value.reduce<string[]>((acc, cur) => {
      if (isString(cur)) {
        acc.push(cur)
      }
      return acc
    }, [])
  }
  else if (isString(value)) {
    return [value]
  }
  else {
    return []
  }
}
