import { YscI18nError } from '~/utils/error/YscI18nError'

export function useHandleI18nError() {
  const { $i18n: { locale } } = useNuxtApp()
  const report = useErrorReport()
  const route = useRoute()

  return (error: Error & { type?: 'field' | 'link', key?: string }) => {
    const yscError = new YscI18nError({
      type: error.type,
      key: error.key,
      locale: toValue(locale),
      route: route?.fullPath,
    })

    report(yscError)

    return yscError
  }
}
