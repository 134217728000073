import { YscApiError } from './YscApiError'
import type { YscApiErrorParams } from './YscApiError'
import { PAYMENT_SUMMARY_GET_UNKNOWN, PAYMENT_SUMMARY_POST_UNKNON } from './errorFingerprints'

export class YscApiPaymentSummaryError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = args.apiMethod === 'get' ? PAYMENT_SUMMARY_GET_UNKNOWN : PAYMENT_SUMMARY_POST_UNKNON
  }
}
