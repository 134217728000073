import type { LocationQuery } from '#vue-router'

export const sanitizeCamperQuery = (query: LocationQuery) => {
  const allowedValues: SanitizeQueryAllowedValues = {
    hour_from: {
      type: 'number',
      values: [9, 14],
    },
    hour_to: {
      type: 'number',
      values: [12, 20],
    },
    kilometers: {
      type: 'number',
      values: [0, 100, 200],
    },
    date_from: {
      type: 'date',
    },
    date_to: {
      type: 'date',
    },
  }
  // eslint-disable-next-line prefer-const
  let { mustRedirect, nextQuery, validationErrorLogs } = sanitizeQuery(query, allowedValues)

  if (!nextQuery.date_from && (nextQuery.hour_to || nextQuery.hour_from)) {
    delete nextQuery.hour_to
    delete nextQuery.hour_from
    mustRedirect = true
    validationErrorLogs.push('sanitizeCamperQuery: hours defined but no date range present')
  }

  return { mustRedirect, nextQuery, validationErrorLogs }
}
