<script setup lang="ts">
import type { AppHeaderMenuProps } from '../Header/AppHeaderMenu.vue'

withDefaults(defineProps<AppHeaderMenuProps>(), {
  showAppLink: false,
})

const open = ref(false)
const userStore = useUserStore()
const { isLoggedIn, user } = storeToRefs(userStore)
</script>

<template>
  <div>
    <button
      type="button"
      class="p-2 -mr-2 inline-flex items-center space-x-2"
      :aria-label="$t('commons.navigation.main_menu')"
      @click="open = true"
    >
      <YscAvatar
        :first-name="user?.first_name"
        :picture="user?.profile_picture?.url"
        radius="15"
      />
      <YscIconsBurger class="h-6 w-6 text-black" />
    </button>

    <YscPanel
      v-model="open"
    >
      <div class="h-full overflow-y-scroll">
        <div
          class="sticky right-0 top-0 flex h-16 items-center bg-white px-4"
          :class="{ 'justify-between shadow': isLoggedIn, 'justify-end': !isLoggedIn }"
        >
          <div
            v-if="isLoggedIn && user"
            class="flex items-center space-x-3"
          >
            <YscAvatar
              :first-name="user.first_name"
              :picture="user.profile_picture?.url"
              radius="20"
            />

            <p class="leading-tight text-gray-500">
              {{ $t('commons.welcome') }}
              <br>
              <strong class="font-semibold">
                {{ user.first_name }}
              </strong>
            </p>
          </div>

          <button
            type="button"
            class="btn btn-link btn-icon"
            @click="open = false"
          >
            <YscIconsDelete class="h-5 w-5" />
          </button>
        </div>
        <div class="p-4">
          <AppMenuLinkList :show-app-link="!showAppLink" />
        </div>
      </div>
    </YscPanel>
  </div>
</template>
