import type { YscErrorParams } from './YscError'
import { YscError } from './YscError'
import { NOT_STAFF } from './errorFingerprints'

export class YscStaffError extends YscError {
  constructor(args: YscErrorParams) {
    super(args)
    this.fingerprint = NOT_STAFF
  }
}
