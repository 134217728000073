import type { AvailabilitiesListItemResponse, Camper } from '@yescapa-dev/ysc-api-js/legacy'
import type { LocationQuery } from '#vue-router'
import type { CamperQueryValidator } from '~/types/camperQuery'

export const validateCamperQuery = async ({ query, availabilities, camper }: { query: LocationQuery, availabilities: AvailabilitiesListItemResponse[], camper: Camper }) => {
  if (!Object.keys(query).length) {
    return { mustRedirect: false, nextQuery: query, validationErrorLogs: [] }
  }

  const { mustRedirect, nextQuery, validationErrorLogs } = sanitizeCamperQuery(query)

  if (!nextQuery.date_from) {
    return { mustRedirect, nextQuery, validationErrorLogs }
  }

  const validators: CamperQueryValidator[] = [validateQueryDateAreOpenDays, validateQueryDateRange, validateQueryHourParameters, validateQueryKilometerParameters]
  return validators.reduce(
    ({ mustRedirect: accMustRedirect, nextQuery: query, validationErrorLogs: accValidationErrorLogs }, validator) => {
      const { mustRedirect, nextQuery, validationErrorLogs = [] } = validator({ query, availabilities, camper })

      return {
        mustRedirect: mustRedirect || accMustRedirect,
        nextQuery,
        validationErrorLogs: [...accValidationErrorLogs, ...validationErrorLogs],
      }
    },
    { mustRedirect, nextQuery, validationErrorLogs },
  )
}
