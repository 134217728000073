import { shouldPolyfill as shoulPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'

export default defineNuxtPlugin({
  name: 'ysc:polyfills',
  dependsOn: ['i18n:plugin'],
  enforce: 'pre',
  parallel: true,
  async setup() {
    const { $i18n: { locale } } = useNuxtApp()
    let formattedLocale = toValue(locale)

    // Locale
    if (shouldPolyfillLocale()) {
      await import('@formatjs/intl-locale/polyfill')
    }

    if (formattedLocale.includes('-')) {
      const [top, second] = formattedLocale.split('-')
      formattedLocale = `${top}-${second.toUpperCase()}`
    }

    // NumberFormat
    const unsupportedLocaleNumberFormat = shoulPolyfillNumberFormat(formattedLocale)
    if (unsupportedLocaleNumberFormat) {
      await import('@formatjs/intl-numberformat/polyfill-force')
      if (formattedLocale === 'ca') {
        await import('@formatjs/intl-numberformat/locale-data/ca')
      }
      if (formattedLocale === 'de') {
        await import('@formatjs/intl-numberformat/locale-data/de')
      }
      if (formattedLocale === 'de-AT') {
        await import('@formatjs/intl-numberformat/locale-data/de-AT')
      }
      if (formattedLocale === 'de-CH') {
        await import('@formatjs/intl-numberformat/locale-data/de-CH')
      }
      if (formattedLocale === 'en' || formattedLocale === 'en-US') {
        await import('@formatjs/intl-numberformat/locale-data/en')
      }
      if (formattedLocale === 'en-GB') {
        await import('@formatjs/intl-numberformat/locale-data/en-GB')
      }
      if (formattedLocale === 'en-IE') {
        await import('@formatjs/intl-numberformat/locale-data/en-IE')
      }
      if (formattedLocale === 'es') {
        await import('@formatjs/intl-numberformat/locale-data/es')
      }
      if (formattedLocale === 'fr') {
        await import('@formatjs/intl-numberformat/locale-data/fr')
      }
      if (formattedLocale === 'fr-BE') {
        await import('@formatjs/intl-numberformat/locale-data/fr-BE')
      }
      if (formattedLocale === 'fr-CH') {
        await import('@formatjs/intl-numberformat/locale-data/fr-CH')
      }
      if (formattedLocale === 'it') {
        await import('@formatjs/intl-numberformat/locale-data/it')
      }
      if (formattedLocale === 'it-CH') {
        await import('@formatjs/intl-numberformat/locale-data/it-CH')
      }
      if (formattedLocale === 'nl') {
        await import('@formatjs/intl-numberformat/locale-data/nl')
      }
      if (formattedLocale === 'nl-BE') {
        await import('@formatjs/intl-numberformat/locale-data/nl-BE')
      }
      if (formattedLocale === 'pt-PT') {
        await import('@formatjs/intl-numberformat/locale-data/pt-PT')
      }
    }

    // ListFormat
    const unsupportedLocaleListFormat = shouldPolyfillListFormat(formattedLocale)
    if (unsupportedLocaleListFormat) {
      await import('@formatjs/intl-listformat/polyfill-force')
      if (formattedLocale === 'ca') {
        await import('@formatjs/intl-listformat/locale-data/ca')
      }
      if (formattedLocale === 'de') {
        await import('@formatjs/intl-listformat/locale-data/de')
      }
      if (formattedLocale === 'de-AT') {
        await import('@formatjs/intl-listformat/locale-data/de-AT')
      }
      if (formattedLocale === 'de-CH') {
        await import('@formatjs/intl-listformat/locale-data/de-CH')
      }
      if (formattedLocale === 'en' || formattedLocale == 'en-US') {
        await import('@formatjs/intl-listformat/locale-data/en')
      }
      if (formattedLocale === 'en-GB') {
        await import('@formatjs/intl-listformat/locale-data/en-GB')
      }
      if (formattedLocale === 'en-IE') {
        await import('@formatjs/intl-listformat/locale-data/en-IE')
      }
      if (formattedLocale === 'es') {
        await import('@formatjs/intl-listformat/locale-data/es')
      }
      if (formattedLocale === 'fr') {
        await import('@formatjs/intl-listformat/locale-data/fr')
      }
      if (formattedLocale === 'fr-BE') {
        await import('@formatjs/intl-listformat/locale-data/fr-BE')
      }
      if (formattedLocale === 'fr-CH') {
        await import('@formatjs/intl-listformat/locale-data/fr-CH')
      }
      if (formattedLocale === 'it') {
        await import('@formatjs/intl-listformat/locale-data/it')
      }
      if (formattedLocale === 'it-CH') {
        await import('@formatjs/intl-listformat/locale-data/it-CH')
      }
      if (formattedLocale === 'nl') {
        await import('@formatjs/intl-listformat/locale-data/nl')
      }
      if (formattedLocale === 'nl-BE') {
        await import('@formatjs/intl-listformat/locale-data/nl-BE')
      }
      if (formattedLocale === 'pt-PT') {
        await import('@formatjs/intl-listformat/locale-data/pt-PT')
      }
    }

    // PluralRules
    const unsupportedPluralRulesLocale = shouldPolyfillPluralRules(formattedLocale)
    if (unsupportedPluralRulesLocale) {
      await import('@formatjs/intl-pluralrules/polyfill-force')
      if (formattedLocale === 'ca') {
        await import('@formatjs/intl-pluralrules/locale-data/ca')
      }
      if (['de', 'de-AT', 'de-CH'].includes(formattedLocale)) {
        await import('@formatjs/intl-pluralrules/locale-data/de')
      }
      if (['en', 'en-US', 'en-GB', 'en-IE'].includes(formattedLocale)) {
        await import('@formatjs/intl-pluralrules/locale-data/en')
      }
      if (formattedLocale === 'es') {
        await import('@formatjs/intl-pluralrules/locale-data/es')
      }
      if (['fr', 'fr-BE', 'fr-CH'].includes(formattedLocale)) {
        await import('@formatjs/intl-pluralrules/locale-data/fr')
      }
      if (formattedLocale === 'it' || formattedLocale === 'it-CH') {
        await import('@formatjs/intl-pluralrules/locale-data/it')
      }
      if (formattedLocale === 'nl' || formattedLocale === 'nl-BE') {
        await import('@formatjs/intl-pluralrules/locale-data/nl')
      }
      if (formattedLocale === 'pt-PT') {
        await import('@formatjs/intl-pluralrules/locale-data/pt-PT')
      }
    }
  },
})
