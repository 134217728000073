export function useHandleUnauthenticatedError() {
  const userStore = useUserStore()
  const route = useRoute()

  const { loggedIn } = storeToRefs(userStore)

  return () => {
    loggedIn.value = false
    userStore.removeAuthentication()

    if (!route.query.next) {
      const next = route.fullPath
      if (!next.startsWith('/login')) {
        navigateTo({ name: 'login', query: { next } })
        return true
      }
    }

    return false
  }
}
